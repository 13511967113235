import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import CardSection from 'loan/components/actionCards/shared/CardSection'
import { Button } from 'core/components'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: ${p => p.margin};
`

function Submit({
  submitPrimaryText,
  submitSecondaryText,
  cancelText,
  onSubmitPrimary,
  onSubmitSecondary,
  primaryDisabled,
  secondaryDisabled,
  onCancel
}) {
  return (
    <>
      <CardSection>
        <ButtonsContainer margin='16px 0 0 0'>
          <Button
            readOnly={primaryDisabled}
            secondary
            margin='0 0 0 32px'
            onClick={onSubmitPrimary}
          >
            {submitPrimaryText}
          </Button>
          {submitSecondaryText && (
            <Button
              readOnly={secondaryDisabled}
              secondary
              margin='0 0 0 32px'
              onClick={onSubmitSecondary}
            >
              {submitSecondaryText}
            </Button>
          )}
          {cancelText && (
            <Button secondary color='black' onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </ButtonsContainer>
      </CardSection>
    </>
  )
}

Submit.propTypes = {
  submitPrimaryText: PropTypes.string.isRequired,
  submitSecondaryText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmitPrimary: PropTypes.func,
  onSubmitSecondary: PropTypes.func,
  onCancel: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool
}

export default Submit

// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Proprietary ------------------------
import updateDocumentDescriptor from 'core/actions/documents/updateDocumentDescriptor'
import useEntity from 'core/hooks/useEntity'
import variables from 'core/styles/variables'

// Components -------------------------
import Status from 'core/components/ResourceOverlay/Document/Status'
import { Dialog, Modal, Spinner } from 'core/components'

// Styles -----------------------------
const FileViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`
const DocumentImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`
const Iframe = styled.iframe`
  flex: 1 1 auto;
  border: 0;
  width: 100%;
  height: 100%;
`
const DocumentImage = styled.img`
  width: 100%;
`
const ErrorMessage = styled.div`
  background: ${variables.colorRedLighten};
  padding: 8px 16px;
  font-size: 14px;
  color: ${variables.colorBlack80};
`
const NoDocument = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  font-size: 48px;
`

// Dcoument variables
export const ACCEPTED = 'accepted'
export const REJECTED = 'rejected'
export const DRAFT = 'draft'

const Document = ({ id, mode, borrowerId }) => {
  const dispatch = useDispatch()

  // App context
  const { pathname } = useLocation()
  const isCRM = pathname.includes('crm')

  // Local state
  const [showModal, setShowModal] = useState(false)
  const [statusLabel, setStatusLabel] = useState(null)
  const [statusMessage, setStatusMessage] = useState(null)

  // Documents
  const document = useEntity('documents', id, { personId: borrowerId })
  const { contentType } = document || {}
  const isPdf = contentType === 'application/pdf'

  // Document content url
  const url = document.content

  // Document status
  const status = document.status

  useEffect(() => {
    switch (status) {
      case ACCEPTED:
        setStatusLabel('Accepted document')
        setStatusMessage(null)
        break
      case REJECTED:
        setStatusLabel('Rejected document')
        setStatusMessage(document.rejectionReason)
        break
      default:
        if (isCRM) {
          setStatusLabel('Uploaded document')
          setStatusMessage(
            'This document has not been reviewed. Please review this document and provide a determination in the borrower-agent portal'
          )
        } else {
          setStatusLabel(null)
          setStatusMessage(
            'Does this document meet the requirements that it needs to? Will you accept or reject this document?'
          )
        }
    }
  }, [document, isCRM, status])

  // ----------------------------------
  // On update document
  const onUpdateDocument = body => {
    dispatch(
      updateDocumentDescriptor({
        key: `DocumentDescriptor-${id}`,
        filters: {
          personId: borrowerId,
          documentDescriptorId: id,
          body
        }
      })
    )
  }

  // ----------------------------------
  // Lock document
  const onLockDocument = () => {
    onUpdateDocument({ sensitiveData: true })
    setShowModal(false)
  }

  if (!document.loadingStatus) {
    return null
  }

  return (
    <>
      {document.loadingStatus === 'loading' ? (
        <Spinner />
      ) : (
        <>
          <Status
            label={statusLabel}
            message={statusMessage}
            mode={mode}
            onUpdateDocument={onUpdateDocument}
            sensitiveData={document.sensitiveData}
            showModal={() => setShowModal(true)}
            status={status}
          />
          {url ? (
            <>
              {isPdf ? (
                <FileViewerContainer>
                  <Iframe src={url} title={document.fileName} />
                </FileViewerContainer>
              ) : (
                <DocumentImageContainer>
                  <DocumentImage src={url} />
                </DocumentImageContainer>
              )}
              {showModal && (
                <Modal width={476} onClose={() => setShowModal(false)}>
                  <Dialog
                    title='Lock document'
                    confirmLabel='Yes'
                    onConfirm={() => onLockDocument()}
                    cancelLabel='No'
                    onCancel={() => setShowModal(false)}
                  >
                    <div>
                      Are you sure you want to lock this document; only
                      supervisors will be able to view it?
                    </div>
                  </Dialog>
                </Modal>
              )}
            </>
          ) : (
            <>
              <ErrorMessage>
                Oops, something went wrong. We aren't able to display your
                document.
              </ErrorMessage>
              <NoDocument>No document found</NoDocument>
            </>
          )}
        </>
      )}
    </>
  )
}

Document.propTypes = {
  mode: PropTypes.string,
  id: PropTypes.string,
  borrowerId: PropTypes.string
}

export default Document

// Third party --------------
import PropTypes from 'prop-types'
import React from 'react'
import store from 'core/store'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'

// Proprietary --------------
import { TimeProvider } from 'core/time'
import { useErrorLoggingScope } from 'core/system/useErrorLoggingScope'
import CallModeContextProvider from 'contexts/CallModeContext'
import DraftContextProvider from 'contexts/DraftContext'
import DraftInteractionContextProvider from 'contexts/DraftInteractionContext'
import FileUploadContextProvider from 'contexts/FileUploadContext'
import OpenResourceContextProvider from 'contexts/OpenResourceContext'
import ResourceOverlay from 'core/components/ResourceOverlay'
import SearchedBorrowerContextProvider from 'contexts/SearchedBorrowerContext'
import TelephonyContextProvider from 'contexts/TelephonyContext'
import TwilioConversationContextProvider from 'contexts/TwilioConversationContext'
import TwilioDeviceContextProvider from 'contexts/TwilioDeviceContext'
import VoiceTaskContextProvider from 'contexts/VoiceTaskContext'
import useActivityRenewal from './session/useActivityRenewal'
import { CompanyIdProvider } from './CompanyIdProvider'
import { CustomizationsProvider } from './CustomizationsProvider'
import EmployeeSettingsProvider from './EmployeeSettingsProvider'
import { UserTypeProvider } from './UserTypeProvider'
import { formatTheme } from './customizationsHelpers'

const SystemProviders = props => {
  const { appDomainType, userType, companyId, timezone, company, children } =
    props

  const defaultTheme = formatTheme({})

  useErrorLoggingScope({
    company,
    companyId,
    appDomainType,
    userType,
    timezone
  })

  useActivityRenewal()

  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <CompanyIdProvider companyId={companyId}>
          <UserTypeProvider userType={userType}>
            <TimeProvider timezone={timezone}>
              <CustomizationsProvider company={company}>
                <StyledThemeProvider theme={defaultTheme}>
                  <TwilioConversationContextProvider>
                    <TwilioDeviceContextProvider>
                      <DraftContextProvider>
                        <VoiceTaskContextProvider>
                          <EmployeeSettingsProvider>
                            <SearchedBorrowerContextProvider>
                              <CallModeContextProvider>
                                <OpenResourceContextProvider>
                                  <DraftInteractionContextProvider>
                                    <TelephonyContextProvider>
                                      <ResourceOverlay>
                                        <FileUploadContextProvider>
                                          {children}
                                        </FileUploadContextProvider>
                                      </ResourceOverlay>
                                    </TelephonyContextProvider>
                                  </DraftInteractionContextProvider>
                                </OpenResourceContextProvider>
                              </CallModeContextProvider>
                            </SearchedBorrowerContextProvider>
                          </EmployeeSettingsProvider>
                        </VoiceTaskContextProvider>
                      </DraftContextProvider>
                    </TwilioDeviceContextProvider>
                  </TwilioConversationContextProvider>
                </StyledThemeProvider>
              </CustomizationsProvider>
            </TimeProvider>
          </UserTypeProvider>
        </CompanyIdProvider>
      </ReduxProvider>
    </BrowserRouter>
  )
}

SystemProviders.propTypes = {
  appDomainType: PropTypes.oneOf(['agent', 'borrower', 'admin']),
  userType: PropTypes.oneOf(['agent', 'borrower']),
  children: PropTypes.node,
  timezone: PropTypes.string,
  companyId: PropTypes.string,
  company: PropTypes.object
}

export default SystemProviders

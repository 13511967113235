// Third party ------------------------
import LockIcon from '@material-ui/icons/Lock'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import { moveCursorPositionToEnd, setTextAreaHeight } from 'core/helpers/input'
import variables from 'core/styles/variables'
import {
  ACCEPTED,
  DRAFT,
  REJECTED
} from 'core/components/ResourceOverlay/Document'

// Components -------------------------
import { Button as ButtonBase } from 'core/components'

// Styles
const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${variables.colorBlack30};
`
const Label = styled.div`
  box-sizing: border-box;
  padding: 24px 0 24px 16px;
  flex: 0 0 160px;
  color: ${variables.colorBlack90};
`
const Message = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 24px;
  color: ${variables.colorBlack60};
`
/**
 * Setting outline: 0 because when switching between the
 * default view and edit view, the Reject button always
 * get's selected.
 */
const Button = styled(ButtonBase)`
  outline: 0;
`
const LockIconWrapper = styled.span`
  flex: 0 0 40px;
  padding-top: 24px;
  font-size: 24px;
  color: ${variables.colorBlack90};
`
const LockOutlinedIconWrapper = styled.span`
  flex: 0 0 40px;
  padding-top: 24px;
  font-size: 24px;
  color: ${variables.colorBlack40};
`
const RejectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${p => (p.mode === 'maximized' ? 'center' : 'flex-start')};
  padding: ${p =>
    p.mode === 'maximized' ? '16px 80px 32px 16px' : '8px 80px 8px 16px'};
  background: ${variables.colorBlack10};
`
const RejectionLabel = styled.div`
  margin: 0 16px 8px 0;
  padding-top: ${p => (p.mode === 'maximized' ? '8px' : '0')};
  width: ${p => (p.mode === 'maximized' ? 'auto' : '100%')};
  color: ${variables.colorBlack60};
  font-weight: 500;
  font-size: 12px;
`
const Textarea = styled.textarea`
  flex: 1 1 auto;
  box-sizing: border-box;
  border: 1px solid
    ${props => (props.hasError ? variables.colorRed : variables.colorBlack20)};
  border-radius: 8px;
  padding: 8px;
  color: ${variables.colorBlack60};
  font-size: 16px;
  resize: none;
`
const Save = styled(Button)`
  outline: 0;
  align-items: flex-start;
  color: ${variables.colorGreen};
`
const Error = styled.div`
  margin: 0 0 8px 16px;
  color: ${variables.colorRed};
  font-size: 14px;
`

// Local variables
const REJECT_DOCUMENT_LABEL = 'Reject document'
const REVIEW_MESSAGE =
  'Does this document meet the requirements that it needs to? Will you accept or reject this document?'
const EMPTY_REJECTION_ERROR = 'Rejection reason cannot be empty'

const Status = ({
  label,
  message,
  mode,
  onUpdateDocument,
  sensitiveData,
  showModal,
  status
}) => {
  // Local state
  const [error, setError] = useState(null)
  const [inReviewMode, setInReviewMode] = useState(status === DRAFT)
  const [rejectionReason, setRejectionReason] = useState(message)
  const [showRejection, setShowRejection] = useState(false)

  // Methods
  const _onEdit = () => {
    setError(null)
    setInReviewMode(true)
    setShowRejection(false)
  }

  const _onAccept = () => {
    if (status !== ACCEPTED) {
      onUpdateDocument({ status: ACCEPTED })
    }
    setInReviewMode(false)
  }

  const _onReject = () => {
    setInReviewMode(false)
    setRejectionReason(() => (status === REJECTED ? message : ''))
    setShowRejection(true)
  }

  const _onSave = () => {
    if (rejectionReason.length === 0) {
      setError(EMPTY_REJECTION_ERROR)
      return
    }
    onUpdateDocument({
      status: REJECTED,
      rejectionReason
    })
    setInReviewMode(false)
    setShowRejection(false)
  }

  return (
    <>
      <StatusContainer>
        {label && !inReviewMode && (
          <Label>{showRejection ? REJECT_DOCUMENT_LABEL : label}</Label>
        )}
        <Message hasLabel={label || inReviewMode}>
          {inReviewMode ? REVIEW_MESSAGE : message}
        </Message>
        {inReviewMode ? (
          <>
            <Button
              autofocus='false'
              secondary
              margin='24px 16px 0 16px'
              onClick={_onReject}
            >
              Reject
            </Button>
            <Button secondary margin='24px 40px 0 16px' onClick={_onAccept}>
              Accept
            </Button>
          </>
        ) : (
          <Button secondary margin='24px 40px 0 16px' onClick={_onEdit}>
            Edit
          </Button>
        )}
        {sensitiveData ? (
          <LockIconWrapper>
            <LockIcon
              fontSize='inherit'
              onClick={() => onUpdateDocument({ sensitiveData: false })}
            />
          </LockIconWrapper>
        ) : (
          <LockOutlinedIconWrapper>
            <LockOutlinedIcon fontSize='inherit' onClick={showModal} />
          </LockOutlinedIconWrapper>
        )}
      </StatusContainer>
      {showRejection && (
        <>
          <RejectionContainer mode={mode}>
            <RejectionLabel mode={mode}>REASON FOR REJECTION</RejectionLabel>
            <Textarea
              autoFocus
              hasError={error}
              onChange={e => [
                setRejectionReason(e.target.value),
                setError(null)
              ]}
              onFocus={e => [
                moveCursorPositionToEnd(e),
                setTextAreaHeight(e.target)
              ]}
              onKeyUp={e => setTextAreaHeight(e.target)}
              value={rejectionReason}
            />
            <Save secondary margin='0 0 0 16px' onClick={_onSave}>
              Save
            </Save>
          </RejectionContainer>
          {error && <Error>{error}</Error>}
        </>
      )}
    </>
  )
}

Status.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  message: PropTypes.string,
  mode: PropTypes.string,
  onUpdateDocument: PropTypes.func,
  sensitiveData: PropTypes.bool,
  showModal: PropTypes.func,
  status: PropTypes.oneOf(['accepted', 'rejected', 'draft'])
}

export default Status

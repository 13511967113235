// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'

// Proprietary ------------------------
import variables from 'core/styles/variables'

// Components -------------------------
import MessageTextarea from './MessageTextarea'
import PreviousInteraction from 'core/components/ResourceOverlay/PreviousInteraction'
import TemplateStyles from './TemplateStyles'
import { PeachQuill } from './PeachQuill'

// Styles -----------------------------
const Container = styled.div`
  height: 100%;
  color: ${variables.colorBlack90};
`
const ContentArea = styled.div`
  width: 100%;
  height: ${p => (p.hasPreviousInteractions ? 'auto' : '100%')};

  .ql-container.ql-snow {
    border: none;
  }
  .quill {
    height: 100%;
  }
`
const EditorContainer = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  min-height: 200px;
`

// Methods ----------------------------

const Editor = props => {
  const {
    attachments,
    channel,
    htmlContent,
    id,
    maximized,
    onChangeEditorState,
    onChangePlainText,
    plainText,
    previewOnly,
    previousInteractions,
    quillRef: passedQuillRef,
    templateStyles
  } = props

  const localQuillRef = useRef()
  const quillRef = passedQuillRef || localQuillRef

  const hasPreviousInteractions = previousInteractions?.length > 0

  return (
    <Container>
      <ContentArea hasPreviousInteractions={hasPreviousInteractions}>
        {channel === 'text' ? (
          <MessageTextarea
            noBorderTop
            onChange={value => onChangePlainText(value)}
            padding={maximized ? '8px 32px' : '8px 16px'}
            value={plainText}
          />
        ) : previewOnly === false ? (
          <TemplateStyles
            templateStyles={templateStyles}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            numberOfAttachments={attachments.length}
            variableHeight={hasPreviousInteractions}
          />
        ) : (
          <TemplateStyles
            templateStyles={templateStyles}
            numberOfAttachments={attachments.length}
            variableHeight={hasPreviousInteractions}
          >
            <EditorContainer>
              <PeachQuill
                id={id}
                onChange={onChangeEditorState}
                quillRef={quillRef}
                value={htmlContent}
              />
            </EditorContainer>
          </TemplateStyles>
        )}
        {_.map(previousInteractions, i => {
          return <PreviousInteraction key={i.id} interaction={i} />
        })}
      </ContentArea>
    </Container>
  )
}

Editor.propTypes = {
  attachments: PropTypes.array,
  channel: PropTypes.oneOf(['email', 'mail', 'text']),
  htmlContent: PropTypes.string,
  id: PropTypes.string,
  maximized: PropTypes.bool,
  onChangeEditorState: PropTypes.func,
  onChangePlainText: PropTypes.func,
  plainText: PropTypes.string,
  previewOnly: PropTypes.bool,
  previousInteractions: PropTypes.array,
  quillRef: PropTypes.object,
  templateStyles: PropTypes.string
}

export { ContentArea }
export default Editor

import _ from 'lodash'
import qs from 'qs'

const formatParams = params => {
  if (!_.isObject(params) || _.isEmpty(params)) return ''

  const queryString = qs.stringify(params, { arrayFormat: 'repeat' })

  return queryString
}

const formatUrl = (url, params) => {
  const queryString = formatParams(params)

  if (!queryString) return url

  if (url.includes('?')) {
    return url + '&' + queryString
  } else {
    return url + '?' + queryString
  }
}

export { formatUrl }

import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

const NothingIcon = props => {
  const { fontSize, ...otherProps } = props

  return <SvgIcon fontSize='inherit' {...otherProps} />
}

export default NothingIcon

import { APIendpoint, getStoredToken } from './helpers/tokenHelpers'

export { APIendpoint, getStoredToken }

/** action types */
export const ADD_ASSOCIATION = 'ADD_ASSOCIATION'
export const CANCEL_ESCALATION = 'CANCEL_ESCALATION'
export const CLEAR_DOCUMENT_DESCRIPTORS = 'CLEAR_DOCUMENT_DESCRIPTORS'
export const CLONE_CONTACT = 'CLONE_CONTACT'
export const CREATE_CASE = 'CREATE_CASE'
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const CREATE_DOCUMENT_DESCRIPTORS = 'CREATE_DOCUMENT_DESCRIPTORS'
export const CREATE_DO_NOT_INTERACT = 'CREATE_DO_NOT_INTERACT'
export const CREATE_INTERACTION = 'CREATE_INTERACTION'
export const CREATE_NOTE = 'CREATE_NOTE'
export const CREATE_SUPERCASE = 'CREATE_SUPERCASE'
export const CREATE_GLOBAL_NOTE = 'CREATE_GLOBAL_NOTE'
export const CREATE_LOCAL_NOTE = 'CREATE_LOCAL_NOTE'
export const DELETE_ASSOCIATION = 'DELETE_ASSOCIATION'
export const DELETE_CONTACTS = 'DELETE_CONTACTS'
export const DELETE_DO_NOT_INTERACT = 'DELETE_DO_NOT_INTERACT'
export const DELETE_INTERACTION = 'DELETE_INTERACTION'
export const GET_AUTOPAY_PREVIEW_ERROR = 'GET_AUTOPAY_PREVIEW_ERROR'
export const GET_AUTOPAY_PREVIEW_INTERNAL_ERROR =
  'GET_AUTOPAY_PREVIEW_INTERNAL_ERROR'
export const GET_BOOTSTRAP_DATA = 'GET_BOOTSTRAP_DATA'
export const GET_CASE = 'GET_CASE'
export const GET_CASES = 'GET_CASES'
export const GET_CASE_TYPES = 'GET_CASE_TYPES'
export const GET_COMPANY = 'GET_COMPANY'
export const GET_CONSENTS = 'GET_CONSENTS'
export const GET_CONTACT_V2 = 'GET_CONTACT_V2'
export const GET_CONTACTS_V2 = 'GET_CONTACTS_V2'
export const GET_DOCUMENT_DESCRIPTORS = 'GET_DOCUMENT_DESCRIPTORS'
export const GET_DOCUMENT_WITH_CONTENT = 'GET_DOCUMENT_WITH_CONTENT'
export const GET_DO_NOT_INTERACTS = 'GET_DO_NOT_INTERACTS'
export const GET_DRAFT_POPULATION = 'GET_DRAFT_POPULATION'
export const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const GET_EXPECTED_PAYMENTS_PREVIEW_ERROR =
  'GET_EXPECTED_PAYMENTS_PREVIEW_ERROR'
export const GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR =
  'GET_EXPECTED_PAYMENTS_PREVIEW_INTERNAL_ERROR'
export const GET_GLOBAL_NOTES = 'GET_GLOBAL_NOTES'
export const GET_INTERACTION = 'GET_INTERACTION'
export const GET_INTERACTIONS = 'GET_INTERACTIONS'
export const GET_LEGAL_REPRESENTATIVES = 'GET_LEGAL_REPRESENTATIVES'
export const GET_LOAN_BY_ID = 'GET_LOAN_BY_ID'
export const GET_LOAN_TYPES = 'GET_LOAN_TYPES'
export const GET_LOCAL_NOTE = 'GET_LOCAL_NOTE'
export const GET_LOCAL_NOTES = 'GET_LOCAL_NOTES'
export const GET_MORE_DO_NOT_INTERACTS = 'GET_MORE_DO_NOT_INTERACTS'
export const GET_MORE_INTERACTIONS = 'GET_MORE_INTERACTIONS'
export const GET_MORE_SUPERCASES = 'GET_MORE_SUPERCASES'
export const GET_NOTES = 'GET_NOTES'
export const GET_PAYMENT_INSTRUMENTS = 'GET_PAYMENT_INSTRUMENTS'
export const GET_PEOPLE = 'GET_PEOPLE'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERSON = 'GET_PERSON'
export const GET_POPULATIONS = 'GET_POPULATIONS'
export const GET_SUBJECTS = 'GET_SUBJECTS'
export const GET_SUPERCASE = 'GET_SUPERCASE'
export const GET_SUPERCASES = 'GET_SUPERCASES'
export const GET_TASK_AVAILABILITY = 'GET_TASK_AVAILABILITY'
export const GET_TEAMS = 'GET_TEAMS'
export const GET_TEAM = 'GET_TEAM'
export const GET_VERIFICATIONS = 'GET_VERIFICATIONS'
export const GET_WORKFLOW = 'GET_WORKFLOW'
export const LOADING = 'LOADING'
export const SEND_COMMUNICATION = 'SEND_COMMUNICATION'
export const SEND_COMMUNICATION_FAILED = 'SEND_COMMUNICATION_FAILED'
export const SEND_COMMUNICATION_INTERNAL_SERVER_ERROR =
  'SEND_COMMUNICATION_INTERNAL_SERVER_ERROR'
export const SET_CAN_INTERACT_STATUS = 'SET_CAN_INTERACT_STATUS'
export const SET_TOO_MANY_VERIFICATION_REQUESTS =
  'SET_TOO_MANY_VERIFICATION_REQUESTS'
export const SET_UNABLE_TO_VERIFY_BORROWER = 'SET_UNABLE_TO_VERIFY_BORROWER'
export const START_VERIFICATION = 'START_VERIFICATION'
export const UPDATE_CASE = 'UPDATE_CASE'
export const UPDATE_CASE_ERROR = 'UPDATE_CASE_ERROR'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CONSENTS = 'UPDATE_CONSENTS'
export const UPDATE_DOCUMENT_DESCRIPTOR = 'UPDATE_DOCUMENT_DESCRIPTOR'
export const UPDATE_DO_NOT_INTERACT = 'UPDATE_DO_NOT_INTERACT'
export const UPDATE_ESCALATION = 'UPDATE_ESCALATION'
export const UPDATE_GLOBAL_NOTE = 'UPDATE_GLOBAL_NOTE'
export const UPDATE_INTERACTION = 'UPDATE_INTERACTION'
export const UPDATE_LOCAL_NOTE = 'UPDATE_LOCAL_NOTE'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_PERSON = 'UPDATE_PERSON'
export const UPDATE_SNOOZE = 'UPDATE_SNOOZE'
export const UPDATE_STEP = 'UPDATE_STEP'
export const UPDATE_SUPERCASE = 'UPDATE_SUPERCASE'
export const UPDATE_TASK_AVAILABILITY = 'UPDATE_TASK_AVAILABILITY'
export const UPDATE_UNBOUND_INTERACTION = 'UPDATE_UNBOUND_INTERACTION'
export const UPDATE_WORK_ITEM = 'UPDATE_WORK_ITEM'

/** case enums */
export const BLOCKS = 'blocks'
export const CASE = 'case'
export const CAUSES = 'causes'
export const DOCUMENT = 'document'
export const DUPLICATE = 'duplicate'
export const INTERACTION = 'interaction'
export const LOAN = 'loan'
export const RELATED = 'related'

/**
 * TODO: IMPORT THE <TOPBAR /> COMPONENT TO HERE AND REPLACE
 * IT WITH THE <TITLEBAR /> STYLED COMPONENT IN THE <RESOURCE />
 * COMPONENT.
 */

// Third party ------------------------
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import RichTextEditor from 'react-rte'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'

// Proprietary -------------------------
import addAssociation from 'core/actions/cases/addAssociation'
import deCamelize from 'core/helpers/deCamelize'
import deleteAssociation from 'core/actions/cases/deleteAssociation'
import extractStylesFromHtml from 'core/helpers/extractStylesFromHtml'
import getFullName from 'core/helpers/getFullName'
import getInteractions from 'core/actions/interactions/getInteractions'
import getPreviousInteractions from 'core/actions/interactions/getPreviousInteractions'
import parseInteractionInfo from 'core/helpers/parseInteractionInfo'
import resendCommunication from 'core/actions/communicator/resendCommunication'
import sanitizeContentHtml from 'core/helpers/sanitizeContentHtml'
import updateInteraction from 'core/actions/interactions/updateInteraction'
import updateUnboundInteraction from 'core/actions/interactions/updateUnboundInteraction'
import variables from 'core/styles/variables'
import { DraftInteractionContext } from 'contexts/DraftInteractionContext'
import { INTERACTION, RELATED } from 'core/actions/constants'
import { SearchedBorrowerContext } from 'contexts/SearchedBorrowerContext'
import {
  useBorrowerIdFromPathname,
  useCases,
  useCompanyId,
  useHasPermission,
  useInteraction,
  useIsLoading,
  useRead
} from 'core/hooks'

// Components -------------------------
import Case from './Case'
import From from './From'
import InteractionBottom from './InteractionBottom'
import PreviousInteraction from 'core/components/ResourceOverlay/PreviousInteraction'
import Reason from './Reason'
import Subject from './Subject'
import TemplateStyles from './TemplateStyles'
import To from './To'
import { Dialog, Modal, Spinner } from 'core/components'

// Styles -----------------------------
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`
const Sensitive = styled.div`
  padding: 24px;
  font-style: italic;
`
const Iframe = styled.iframe`
  flex: 1 1 auto;
  border: none;
  width: 100%;
`
const PlainText = styled.div`
  padding: 16px;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`

const SensitiveContent = (
  <Sensitive>
    Content is locked. Only those with access, like a manager, can unlock this
    content.
  </Sensitive>
)
const SensitiveBorrowerOnly = (
  <Sensitive>
    "Account Credential" interactions are never displayed in the CRM because
    they contain information used to verify borrower details
  </Sensitive>
)
const CANNOT_ASSOCIATE_BORROWER = 'CANNOT_ASSOCIATE_BORROWER'
const DISASSOCIATE_BORROWER_WARNING = 'DISASSOCIATE_BORROWER_WARNING'

const Interaction = ({
  borrower,
  borrowerName,
  id,
  maximized,
  onClose,
  setBorrowerId,
  setBorrowerName
}) => {
  const { useContext, useDispatch, useHasPermission } = Interaction.dependencies

  const { id: borrowerId } = borrower

  const dispatch = useDispatch()

  const { onOpenNewDraftInteraction } = useContext(DraftInteractionContext)

  // Company id
  const companyId = useCompanyId()

  // Local state
  const [showModal, setShowModal] = useState(null)

  // Searched borrower context stuff
  const { searchedBorrower, setSearchedBorrower } = useContext(
    SearchedBorrowerContext
  )

  // Borrower ID from pathname
  const borrowerIdFromPathname = useBorrowerIdFromPathname()

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  // Entities & Collections
  const interaction = useInteraction(id)
  const {
    channel,
    content,
    direction,
    id: interactionId,
    previousInteractionId,
    sensitive,
    sensitiveBorrowerOnly,
    theme
  } = interaction || {}

  const casesCollection = useCases({
    personId: interaction?.personId
  }).byId
  const cases = _.orderBy(_.values(casesCollection), ['createdAt'], ['desc'])

  const [previousInteractions, loadingPreviousInteractions] =
    useRead(async () => {
      if (!previousInteractionId) return []
      const interactions = await dispatch(
        getPreviousInteractions({
          companyId,
          id: previousInteractionId,
          key: 'GetPreviousInteractions',
          previousInteractions: []
        })
      )
      /**
       * Sort previous interactions by createdAt value in
       * descending order.
       */
      return _.reverse(_.sortBy(interactions, 'createdAt'))
    }, [previousInteractionId])

  // Local variables
  const isInteractionLoading = useIsLoading(
    `Interactions.byId(id=${interaction?.id})`
  )
  const isLoading = isInteractionLoading || loadingPreviousInteractions

  const { from, startedAt, to, body, name } = parseInteractionInfo(interaction)

  const templateStyles = extractStylesFromHtml(body)

  const isInbound = direction === 'inbound'

  useEffect(() => {
    setBorrowerId(interaction?.personId || null)
  }, [interaction, setBorrowerId])

  // Set borrower name once -----------
  useEffect(() => {
    const borrowerNameBestGuess = !borrowerId
      ? 'Unidentified borrower'
      : getFullName(borrower)
    setBorrowerName(borrowerNameBestGuess)
  }, [borrower, borrowerId, borrowerName, setBorrowerName])

  const fromName = isInbound ? borrowerName : name

  const addPaddingToContent =
    isInbound && (channel === 'email' || channel === 'text')

  const attachments = interaction.content?.attachments || []

  let pdfContent
  try {
    window.atob(interaction.content.content)
    pdfContent = interaction.content.content
  } catch {
    pdfContent = null
  }

  const selectOptionFormat = c => ({
    label: `${c.id} ${c.name ?? deCamelize(c.caseType)}`,
    value: c.id
  })

  // Cases options
  const casesOptions = useMemo(() => {
    const hiddenCases = ['completed', 'canceled']

    const filteredCases = _.filter(cases, c => !hiddenCases.includes(c.status))
    return _.map(filteredCases, c => selectOptionFormat(c))
  }, [cases])

  // Associated cases
  const associations = _.filter(cases, c => {
    return _.some(c.associations, {
      objectId: interactionId,
      objectType: 'interaction'
    })
  })
  const associatedCases = _.map(associations, c => selectOptionFormat(c))

  const plainTextOrHtml = useMemo(() => body, [body])

  const editorState = useMemo(() => {
    if (interaction.channel === 'text') {
      return null
    }
    const sanitizedHtml = sanitizeContentHtml(plainTextOrHtml)
    return RichTextEditor.createValueFromString(sanitizedHtml, 'html')
  }, [plainTextOrHtml, interaction])

  const showDangerouslySetHtml = channel === 'email' || channel === 'mail'

  // Loading state
  if (!interaction.loadingStatus || interaction.loadingStatus === 'loading') {
    return null
  }
  if (interaction.loadingStatus !== 'success') {
    return `Loading ${interaction.loadingStatus}.`
  }

  // Methods
  const _onCaseSelect = (option = []) => {
    if (!borrowerId) {
      alert('Cannot associate case when an interaction has no known borrower.')
      return
    }

    if (option?.length > associatedCases.length) {
      const caseId = option[option.length - 1].value
      dispatch(
        addAssociation({
          key: `AddAssociation-${caseId}`,
          filters: {
            personId: borrowerId,
            caseId
          },
          body: {
            objectType: INTERACTION,
            objectId: interactionId,
            relation: RELATED
          }
        })
      )
    } else {
      let caseId
      const oldIds = associatedCases.map(_case => _case.value)
      const newIds = option?.map(o => o.value) || []
      oldIds.forEach(id => {
        if (!newIds.includes(id)) {
          caseId = id
        }
      })
      dispatch(
        deleteAssociation({
          key: `DeleteAssociation-${caseId}`,
          personId: borrowerId,
          caseId,
          query: `objectType=${INTERACTION}&objectId=${interactionId}`,
          objectId: interactionId
        })
      )
    }
  }

  const _associateBorrower = _borrowerId => {
    dispatch(
      updateUnboundInteraction({
        body: {
          personId: _borrowerId
        },
        companyId,
        id: interactionId,
        key: `Interactions.byId(id=${interactionId})`
      })
    )
    const newBorrowerName = getFullName(borrower)
    setBorrowerName(newBorrowerName)
    setBorrowerId(_borrowerId)
  }

  const _disassociateBorrower = () => {
    setShowModal(null)
    dispatch(
      updateInteraction({
        body: {
          personId: null
        },
        personId: borrower.id,
        id: interactionId,
        personIdIsNull: true,
        key: `Interactions.byId(id=${interactionId})`
      })
    )
    setSearchedBorrower(null)
    setBorrowerId(null)
    setBorrowerName('Unidentified borrower')
  }

  const _onAssociateDisassociateBorrower = () => {
    if (interaction.personId) {
      setShowModal(DISASSOCIATE_BORROWER_WARNING)
    } else {
      if (searchedBorrower || borrowerIdFromPathname) {
        _associateBorrower(searchedBorrower?.id || borrowerIdFromPathname)
      } else {
        setShowModal(CANNOT_ASSOCIATE_BORROWER)
      }
    }
  }

  const openAttachment = base64String => {
    const src = `data:application/pdf;base64,${base64String}`
    const iframe = `<iframe width='100%' height='100%' src='${src}'></iframe>`
    const newTab = window.open()
    newTab.document.open().write(iframe)
  }

  const onResend = async () => {
    /**
     * We're going to close the interaction right away for
     * now. In the next iteraction, we will have a preview
     * mode that comes just before the actual send. After
     * we send the new interaction, we will want to refetch
     * all the interactions.
     */
    onClose()

    /**
     * This action returns the id of the newly created
     * interaction. You can use that if we eventually want
     * to also make any additional case associations with
     * the interaction in case a particular interaction has
     * multiple cases it's associated.
     */
    await dispatch(
      resendCommunication({ key: 'ResendCommunication', id: interactionId })
    )

    /**
     * Refetch all interactions so the interaction history
     * automatically shows the newly created interaction.
     */
    dispatch(
      getInteractions({
        key: 'GetInteractions',
        filters: { personId: interaction.personId }
      })
    )
  }

  const onReply = channel => {
    if (!channel) return
    onClose()
    onOpenNewDraftInteraction({
      channel: channel,
      borrowerId: interaction.personId,
      companyId,
      previousInteractions: [interaction, ...previousInteractions],
      associatedCase: associatedCases ? associatedCases[0] : null
    })
  }

  return (
    <>
      <MainContainer>
        <From
          borrowerId={borrowerId || borrowerIdFromPathname}
          channel={channel}
          contactInfo={from}
          date={startedAt}
          direction={direction}
          interaction={interaction}
          maximized={maximized}
          name={fromName}
          onAssociateDisassociateBorrower={_onAssociateDisassociateBorrower}
        />
        <ScrollableArea>
          {borrowerId && (
            <Case
              isControlled
              maxMenuHeight={120}
              maximized={maximized}
              multiSelect
              onChange={_onCaseSelect}
              options={casesOptions}
              value={associatedCases}
            />
          )}
          {theme && <Reason maximized={maximized} theme={theme} readOnly />}
          {borrowerId ? (
            <To
              maximized={maximized}
              contactInfo={to}
              hideLock
              borrowerId={borrower.id}
              readOnly
            />
          ) : null}
          {channel === 'email' && (
            <Subject
              maximized={maximized}
              subject={content?.subject}
              readOnly
            />
          )}
          {sensitiveBorrowerOnly ? (
            SensitiveBorrowerOnly
          ) : sensitive && !canReadSensitive ? (
            SensitiveContent
          ) : pdfContent ? (
            <Iframe
              src={`data:application/pdf;base64,${pdfContent}`}
              title={interactionId}
            />
          ) : showDangerouslySetHtml ? (
            <TemplateStyles
              setHeightAuto={previousInteractions?.length > 0}
              templateStyles={templateStyles}
              addPadding={addPaddingToContent}
              dangerouslySetInnerHTML={{ __html: editorState?._cache?.html }}
            />
          ) : channel === 'text' ? (
            <PlainText>{plainTextOrHtml}</PlainText>
          ) : null}
          {_.map(previousInteractions, i => {
            return (
              <PreviousInteraction setHeightAuto key={i.id} interaction={i} />
            )
          })}
        </ScrollableArea>
        <InteractionBottom
          attachments={attachments}
          channel={channel}
          hideResendButton={isInbound}
          openAttachment={openAttachment}
          onReply={onReply}
          onResend={onResend}
        />
        {isLoading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </MainContainer>

      {showModal && (
        <Modal width={476} onClose={() => setShowModal(null)}>
          {showModal === DISASSOCIATE_BORROWER_WARNING ? (
            <Dialog
              title='Disassociate borrower'
              confirmLabel='Yes'
              onConfirm={_disassociateBorrower}
              cancelLabel='No'
              onCancel={() => setShowModal(null)}
            >
              <div>
                Are you sure you want to remove borrower association which will
                leave the interaction without borrower ID.
              </div>
            </Dialog>
          ) : showModal === CANNOT_ASSOCIATE_BORROWER ? (
            <Dialog
              title='Associate with borrower'
              confirmLabel='Ok'
              onConfirm={() => setShowModal(null)}
            >
              <div>
                To associate with a borrower please search for the borrower in
                “Find a borrower”. Select the borrower to confirm their contact
                details, then you can "Associate with borrower" in the
                interaction.
              </div>
            </Dialog>
          ) : null}
        </Modal>
      )}
    </>
  )
}

Interaction.propTypes = {
  borrower: PropTypes.object,
  borrowerName: PropTypes.string,
  id: PropTypes.string,
  /** If the containing resource window is maximized */
  maximized: PropTypes.bool,
  onClose: PropTypes.func,
  setBorrowerId: PropTypes.func,
  setBorrowerName: PropTypes.func
}

Interaction.dependencies = {
  useContext,
  useDispatch,
  useHasPermission
}

export default Interaction

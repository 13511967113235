import { createDocumentUploadLink } from './createDocumentUploadLink'
import formatUploadLink from 'core/components/ResourceOverlay/helpers/formatUploadLink'

const insertUploadLink = async ({
  quillRef,
  borrowerId,
  contactId,
  caseId
}) => {
  const quill = quillRef.current.getEditor()
  const range = quill.getSelection(true)
  if (!range) return

  quill.disable()
  try {
    const { expiresAt, url } = await createDocumentUploadLink(
      borrowerId,
      contactId,
      caseId
    )

    const { index } = range

    const delta = formatUploadLink(expiresAt, url, index)

    if (index === 0) {
      delta.ops.shift()
    }

    quill.updateContents(delta)
  } catch {
    // TODO: Add helpful UI to indicate error when fetching download link
  }

  quill.enable()
  quill.focus()
}

export { insertUploadLink }

/**
 * TODO: IMPORT THE <TOPBAR /> COMPONENT TO HERE AND REPLACE
 * IT WITH THE <TITLEBAR /> STYLED COMPONENT IN THE <RESOURCE />
 * COMPONENT.
 */

// Third party ------------------------
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react'
import _ from 'lodash'
import htmlToText from 'html-to-text'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'

// Proprietary ------------------------
import bulkSend from 'core/actions/bulkOperations/bulkSend'
import extractStylesFromHtml from 'core/helpers/extractStylesFromHtml'
import getCases from 'core/actions/cases/getCases'
import getFreeFormEmailPreview from 'core/actions/communicator/getFreeFormEmailPreview'
import getFullName from 'core/helpers/getFullName'
import getInteractions from 'core/actions/interactions/getInteractions'
import getSubjects from 'core/actions/communicator/getSubjects'
import getTemplatePreview from 'core/actions/communicator/getTemplatePreview'
import isCollectionLoading from 'core/helpers/isCollectionLoading'
import sanitizeContentHtml from 'core/helpers/sanitizeContentHtml'
import sendCommunication from 'core/actions/communicator/sendCommunication'
import sendFreeFormEmail from 'core/actions/communicator/sendFreeFormEmail'
import useFirstLoanIdOfCase from 'core/components/ResourceOverlay/hooks/useFirstLoanIdOfCase'
import { DraftInteractionContext } from 'contexts/DraftInteractionContext'
import { insertUploadLink } from 'core/components/ResourceOverlay/PeachQuill'
import { interactionThemes } from 'config/constants'
import { outboundInteractionReasonOptions } from 'core/helpers/getReasonOptions'
import { useTime } from 'core/time'
import { variables } from 'core/styles'
import {
  addRePrefix,
  editDraftPreview,
  extractEditableContentHtml,
  getContactInfo,
  makeContext
} from './helpers'
import {
  useCases,
  useDocuments,
  useEntity,
  useIsLoading,
  usePrevious,
  usePopulationsInfo,
  useWrite
} from 'core/hooks'
import {
  useCanSendInteraction,
  useFreeFormContext,
  useHasMadeMinimumEdits,
  useIsContentEmpty
} from './hooks'

// Components -------------------------
import Editor from 'core/components/ResourceOverlay/Editor'
import Header from 'core/components/ResourceOverlay/Header'
import Iframe from 'core/components/ResourceOverlay/Iframe'
import Reason from 'core/components/ResourceOverlay/Reason'
import SendRow from 'core/components/ResourceOverlay/SendRow'
import Subject from 'core/components/ResourceOverlay/Subject'
import SupercaseId from 'core/components/ResourceOverlay/SupercaseId'
import To from 'core/components/ResourceOverlay/To'
import ToPopulations from 'core/components/ResourceOverlay/ToPopulations'
import { Dialog, Modal, Spinner } from 'core/components'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`
const NoTemplateFound = styled.div`
  padding: 16px;
  height: 100%;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`

const NO_TEMPLATE_FOUND_ERROR =
  'No suitable template found. Please choose another template.'

const DRAFT_MODE = 'draft_mode'
const PREVIEW_MODE = 'preview_mode'

const DraftInteraction = ({
  borrower,
  borrowerName,
  id,
  maximized,
  onClose,
  previousInteractions: passedPreviousInteractions,
  setBorrowerName,
  supercaseId: passedSupercaseId
}) => {
  const {
    getTemplatePreview,
    sendCommunication,
    useContext,
    useDispatch,
    useEntity,
    useFirstLoanIdOfCase,
    useSelector,
    useState,
    useTime,
    bulkSend,
    useWrite
  } = DraftInteraction.dependencies

  const { formatRelative } = useTime()
  const dispatch = useDispatch()
  const quillRef = useRef()

  const { draftInteractionsById, onUpdateDraftInteraction, onDeleteDraft } =
    useContext(DraftInteractionContext)

  const draftFromStorage = useMemo(
    () => draftInteractionsById[id] || {},
    [draftInteractionsById, id]
  )

  const {
    channel: channelFromDraftStorage,
    borrowerId: borrowerIdFromDraftStorage,
    companyId,
    previousInteractions: previousInteractionsFromDraftStorage,
    associatedCase,
    supercaseId: supercaseIdFromDraftStorage
  } = draftFromStorage

  const borrowerId = useMemo(() => {
    return borrower?.id ?? borrowerIdFromDraftStorage ?? null
  }, [borrower, borrowerIdFromDraftStorage])

  const previousInteractions = useMemo(() => {
    return (
      previousInteractionsFromDraftStorage ?? passedPreviousInteractions ?? []
    )
  }, [previousInteractionsFromDraftStorage, passedPreviousInteractions])

  const supercaseId = useMemo(() => {
    return passedSupercaseId ?? supercaseIdFromDraftStorage ?? null
  }, [passedSupercaseId, supercaseIdFromDraftStorage])

  const isSupercase = !!supercaseId

  const { activePopulations, activeBorrowers } = usePopulationsInfo(supercaseId)

  /**
   * For now we're only going to support showing the latest
   * previous interaction. Once we decided to support
   * showing multiple previous interactions this logic
   * should be changed and this comment should be removed.
   */
  const previousInteraction = useMemo(() => {
    return previousInteractions?.length > 0 ? previousInteractions[0] : {}
  }, [previousInteractions])

  const isReply = !_.isEmpty(previousInteraction)

  const {
    personId: personIdFromPreviousInteraction,
    content,
    channel: channelFromPreviousInteraction
  } = previousInteraction

  const { from, personalizations } = content || {}
  const { name: fromName, email: fromEmail } = from || {}
  const firstPersonalization =
    personalizations?.length > 0 ? personalizations[0] : {}
  const { to: toArray } = firstPersonalization
  const to = toArray?.length > 0 ? toArray[0] : {}
  const { name: toName, email: toEmail } = to

  const isUnbound = !borrowerId

  const isReplyToUnboundInteraction =
    isReply && !personIdFromPreviousInteraction

  const channel = channelFromDraftStorage ?? channelFromPreviousInteraction
  const isEmail = channel === 'email'

  const initialContact =
    previousInteraction.contactId ?? draftFromStorage.contact ?? null

  // Entities & Collections
  const cases = useCases({ personId: borrowerId })
  const company = useEntity('companies', companyId)
  const { brandName } = company || {}
  const allSubjects = useSelector(state => state.communicator.subjects) ?? []
  const subjects = allSubjects.filter(s => s.agentCanSend === true)
  const documentsCollection = useDocuments({
    personId: borrowerId
  }).byId
  const documents = _.sortBy(
    _.filter(documentsCollection, { archived: false }),
    'createdAt'
  )

  // Loading status
  const isTemplateLoading = useIsLoading(`DraftInteraction-${id}`)
  const isCasesLoading = isCollectionLoading(cases)
  /**
   * Templates are not supported by supercase interactions
   * for now so we don't need any initial loading state
   * if the draft is specifically for supercase emails.
   * We also don't need to care about cases for supercases
   * either.
   */
  const isLoading =
    (isTemplateLoading || (isCasesLoading && !isUnbound)) && !isSupercase

  const { subjectLine: subjectLineFromDraft } = draftFromStorage
  const { content: contentFromPreviousInteraction } = previousInteraction || {}
  const { subject: subjectLineFromPrevious } =
    contentFromPreviousInteraction || {}

  /**
   * This method is intended to add the correct subject line prefix
   * of 'Re:' if it's a reply and the subject line does not start
   * with 'Re:'
   */
  const getInitialReplySubjectLine = () => {
    if (isReply) {
      if (!!subjectLineFromDraft) return addRePrefix(subjectLineFromDraft)
      if (!!subjectLineFromPrevious) return addRePrefix(subjectLineFromPrevious)
      return ''
    }
    return subjectLineFromDraft ?? ''
  }
  const replySubjectLine = getInitialReplySubjectLine()

  // Local state
  const [htmlContent, setHtmlContent] = useState(
    draftFromStorage.htmlContent ?? ''
  )
  const [plainText, setPlainText] = useState(draftFromStorage.plainText ?? '')
  const [bodyHasBeenEdited, setBodyHasBeenEdited] = useState(
    draftFromStorage.bodyHasBeenEdited ?? false
  )
  const [caseId, setCaseId] = useState(draftFromStorage.caseId)
  const [contact, setContact] = useState(initialContact)
  const [populationId, setPopulationId] = useState('all')
  const [hasNoTemplateFoundError, setHasNoTemplateFoundError] = useState(
    draftFromStorage.hasNoTemplateFoundError ?? false
  )
  const [modalConfirmText, setModalConfirmText] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [modalType, setModalType] = useState(null)
  /**
   * TODO: ...
   * For some reason using the usePrevious hook for keeping
   * track of the previously selected template doesn't work.
   * This works for now, but maybe should be looked into
   * more deeply in the future.
   */
  const [prevTemplate, setPrevTemplate] = useState(
    draftFromStorage.prevTemplate
  )
  /** Note on subject/template: If a template is not a
   * preview, then we'll use that template name. Otherwise
   * if a template is editable or if no template is
   * selected, then we'll use `freeFormBranded`. This
   * template type will take the editor content and insert
   * it into a template with a header logo, footer and a
   * date near the top for mail interactions.
   */
  const [template, setTemplate] = useState(
    draftFromStorage.template ?? 'freeFormBranded'
  )
  const [templateStyles, setTemplateStyles] = useState(
    draftFromStorage.templateStyles ?? ''
  )
  const [theme, setTheme] = useState(draftFromStorage.theme)
  const [showModal, setShowModal] = useState(false)
  const [showSendErrorModal, setShowSendErrorModal] = useState(false)
  const [subjectLine, setSubjectLine] = useState(replySubjectLine)
  const [hasEditableContent, setHasEditableContent] = useState(
    draftFromStorage.hasEditableContent ?? false
  )
  const [attachments, setAttachments] = useState(
    draftFromStorage.attachments ?? []
  )
  const [mode, setMode] = useState(DRAFT_MODE)

  const loanId = useFirstLoanIdOfCase({ borrowerId, caseId })

  // Local variables ------------------
  const isPreviewMode = mode === PREVIEW_MODE
  const prevCaseId = usePrevious(caseId)
  const shouldSendFreeFormEmail = isEmail && isUnbound
  /**
   * Subject refers to a single subject details object we get from
   * the array of subjects from GET /communicator/subjects endpoint
   */
  const subject = _.find(subjects, s => s.subject === template)

  const hasContact = !!contact?.id
  const hasTheme = !!theme?.value

  const body = useMemo(() => {
    if (channel === 'text') {
      return plainText
    } else {
      return htmlContent
    }
  }, [plainText, htmlContent, channel])
  /**
   * Some templates come with notes to agent to modify the template before
   * sending them. These sections start with `[AGENT: blah blah...`. For now
   * we're doing just a crude search of the beginning of the section. Later
   * we should find a better solution to make sure the entire agent note
   * section is accurately updated.
   */
  const hasMadeMinimumEdits = useHasMadeMinimumEdits(subject, body)

  const isContentEmpty = useIsContentEmpty(channel, plainText, htmlContent)

  const contactInfo = getContactInfo({
    channel: isUnbound ? channelFromPreviousInteraction : channel,
    contact,
    content,
    isReply
  })

  const context = useFreeFormContext(body, channel, company, subjectLine)

  useEffect(() => {
    const newDraftState = {
      attachments,
      bodyHasBeenEdited,
      borrowerId,
      caseId,
      channel,
      companyId,
      contact,
      hasEditableContent,
      hasNoTemplateFoundError,
      htmlContent,
      id,
      plainText,
      previousInteractions,
      prevTemplate,
      subjectLine,
      supercaseId,
      template,
      templateStyles,
      theme
    }
    onUpdateDraftInteraction({ id, newState: newDraftState })
  }, [
    onUpdateDraftInteraction,
    attachments,
    bodyHasBeenEdited,
    borrowerId,
    caseId,
    channel,
    companyId,
    contact,
    hasEditableContent,
    hasNoTemplateFoundError,
    htmlContent,
    id,
    plainText,
    previousInteractions,
    prevTemplate,
    subjectLine,
    supercaseId,
    template,
    templateStyles,
    theme
  ])

  /**
   * Fetch all subjects if currently not available in the redux store
   */
  useEffect(() => {
    if (!subjects.length) {
      dispatch(
        getSubjects({
          key: 'GetSubjects'
        })
      )
    }
  }, [dispatch, subjects])

  // Set borrower name once -----------
  useEffect(() => {
    const foundBorrowerName = getFullName(borrower)
    if (!borrowerName && !!foundBorrowerName) {
      setBorrowerName(foundBorrowerName)
    }
  }, [borrower, borrowerName, setBorrowerName])

  const resetBody = useCallback(
    value => {
      if (channel === 'text') {
        const newValue = value.length > 0 ? value : `${brandName}: `
        setPlainText(newValue)
        draftFromStorage.plainText = newValue
      } else {
        setHtmlContent(value)
        draftFromStorage.html = value
      }
      // The caller of resetBody is responsible of calling saveDraft at the end.
    },
    [brandName, channel, draftFromStorage]
  )

  const updateSubjectLine = useCallback(value => {
    setSubjectLine(value)
  }, [])

  const updateTheme = useCallback(value => {
    setTheme(value)
  }, [])

  // Methods --------------------------
  const onSendFreeFormEmail = async () => {
    const { name, value } = contact || {}
    const relevantEmail = _.isEmpty(previousInteraction)
      ? value
      : previousInteraction.direction === 'outbound'
      ? toEmail
      : fromEmail
    const relevantName = _.isEmpty(previousInteraction)
      ? name
      : previousInteraction.direction === 'outbound'
      ? toName
      : fromName
    const to = [{ email: relevantEmail, name: relevantName }]

    return dispatch(
      sendFreeFormEmail({
        contentHtml: htmlContent,
        contentPlain: plainText || htmlToText.fromString(body),
        key: `DraftInteraction-${id}`,
        personId: borrowerId || null,
        previousInteractionId: previousInteraction.id || null,
        subjectLine,
        theme: theme.value,
        to
      })
    )
  }

  const onSendSupercaseInteraction = async () => {
    /**
     * Since we're not supporting any other template
     * types except for `freeFormBranded`, we'll just
     * hard code that param in the makeContext() method
     * for now.
     */
    return dispatch(
      bulkSend({
        key: `DraftInteraction-${id}`,
        attachments,
        channel,
        context: makeContext('freeFormBranded', context),
        subject: template,
        supercaseId,
        theme: theme.value,
        populationId
      })
    )
  }

  const onSendCommunication = async () => {
    const subject =
      hasEditableContent || !template || bodyHasBeenEdited
        ? 'freeFormBranded'
        : template

    return dispatch(
      sendCommunication({
        attachments,
        caseId,
        channel,
        companyId,
        contactId: contact?.id,
        context: makeContext(subject, context),
        key: `DraftInteraction-${id}`,
        personId: borrowerId,
        previousInteractionId: previousInteraction.id || null,
        subject,
        theme: theme.value,
        loanId
      })
    )
  }

  const [onSend, isSending, , sendError] = useWrite(async () => {
    try {
      isSupercase
        ? await onSendSupercaseInteraction()
        : shouldSendFreeFormEmail
        ? await onSendFreeFormEmail()
        : await onSendCommunication()

      if (!isUnbound && !isSupercase) {
        /**
         * Refetch interactions so the newly sent one will show
         * up in the interaction history.
         */
        dispatch(
          getInteractions({
            key: 'GetInteractions',
            filters: { personId: borrowerId }
          })
        )
        /**
         * Refetch cases so any case association made in the
         * draft interaction will show accordinly when the new
         * interaction is viewed from the interaction history
         * section.
         */
        dispatch(
          getCases({
            filters: { personId: borrowerId },
            key: `cases.byId(personId=${borrowerId})`
          })
        )
      }
      onClose()
      onDeleteDraft(id)
    } catch (e) {
      setShowSendErrorModal(true)
      throw e
    }
  })

  const [onPreview, isFetchingPreview, draftPreview] = useWrite(async () => {
    if (isEmail && ((isUnbound && !isSupercase) || bodyHasBeenEdited)) {
      return dispatch(
        getFreeFormEmailPreview({
          key: `DraftInteraction-${id}`,
          subjectLine,
          contentHtml: htmlContent,
          contentPlain: plainText
        })
      )
    } else {
      /**
       * The fetch preview endpoint requires a borrowerId to
       * be passed. For en masse interactions for a supercase
       * population, we'll use the first borrowerId in the
       * list of activeBorrowers. This is fine since it's
       * only used to fetch a preview. It will not be used
       * for anything else.
       */
      const $borrowerId = isSupercase ? activeBorrowers[0] : borrowerId
      const adjustedTemplate = bodyHasBeenEdited ? 'freeFormBranded' : template
      return dispatch(
        getTemplatePreview({
          key: `DraftInteraction-${id}`,
          subject: adjustedTemplate,
          personId: $borrowerId,
          channel,
          contactId: contact?.id,
          caseId,
          context: makeContext(adjustedTemplate, context),
          companyId
        })
      )
    }
  })

  // Change case event
  const onChangeCaseId = useCallback(
    newCaseId => {
      setCaseId(newCaseId)
      if (bodyHasBeenEdited) {
        setModalConfirmText('Change Case')
        setModalMessage(
          'Are you sure you want to change the associated case? All fields will be reset if you change the case.'
        )
        setModalType('caseChange')
        setShowModal(true)
      } else {
        setSubjectLine('')
        setBodyHasBeenEdited(false)
        resetBody('')
        setTemplate('freeFormBranded')
      }
      updateTheme(null)
      setAttachments([])
    },
    [bodyHasBeenEdited, resetBody, updateTheme]
  )

  // Change template event
  const changeTemplate = async newTemplate => {
    // Basic resetting
    resetBody('')
    setHasNoTemplateFoundError(false)

    const _subject = subjects.find(s =>
      newTemplate ? s.subject === newTemplate : s.subject === template
    )

    // Update subject line based on template
    if (newTemplate !== 'freeFormBranded' && template !== 'freeFormBranded') {
      const subjectTitle = _subject?.title || ''
      setSubjectLine(subjectTitle)
    } else {
      setSubjectLine('')
    }

    // Get template preview if not of type 'freeFormBranded'
    const freeForms = ['freeFormBranded']
    const notFreeForm =
      !freeForms.includes(newTemplate) || !freeForms.includes(template)
    if (notFreeForm) {
      try {
        const bodyResp = await dispatch(
          getTemplatePreview({
            key: `DraftInteraction-${id}`,
            subject: newTemplate ?? template,
            personId: borrowerId,
            channel,
            contactId: contact?.id,
            caseId,
            context: makeContext(newTemplate ?? template, context),
            companyId
          })
        )
        if (channel === 'text') {
          resetBody(bodyResp.contentText)
        } else {
          const stylesString = extractStylesFromHtml(bodyResp.contentHtml)
          setTemplateStyles(stylesString)

          /**
           * For editable templates we take just the editable
           * portion of the template and display it in the
           * editor. Otherwise show the sanitized html content.
           */
          if (_subject?.previewOnly) {
            const content = extractEditableContentHtml(bodyResp.contentHtml)
            resetBody(content.editableContent)
            setHasEditableContent(true)
          } else {
            const sanitizedHtml = sanitizeContentHtml(bodyResp.contentHtml)
            resetBody(sanitizedHtml)
            setHasEditableContent(false)
          }
        }
      } catch (e) {
        // Simple error handling
        if (e.status === 400) {
          setHasNoTemplateFoundError(true)
          resetBody(NO_TEMPLATE_FOUND_ERROR)
        }
      }

      setBodyHasBeenEdited(false)
    }
    if (notFreeForm) {
      const selectedTemplate = newTemplate || template
      const selectedSubject = _.find(subjects, { subject: selectedTemplate })
      const { theme, title } = selectedSubject
      updateTheme({
        label: interactionThemes[theme],
        value: theme
      })
      updateSubjectLine(title)
    } else {
      updateTheme(null)
      updateSubjectLine(null)
    }
  }

  const onChangeTemplate = newTemplate => {
    setPrevTemplate(template)
    setTemplate(newTemplate)
    if (!bodyHasBeenEdited) {
      changeTemplate(newTemplate)
    } else {
      setModalConfirmText('Change Template')
      setModalMessage(
        'Are you sure you want to change the template? All changes will be lost, if you do.'
      )
      setModalType('templateChange')
      setShowModal(true)
    }
  }

  // Modal Confirm/Cancel events
  const onConfirmModal = () => {
    if (modalType === 'caseChange') {
      setSubjectLine('')
      setBodyHasBeenEdited(false)
      resetBody('')
      setTemplate('freeFormBranded')
    } else {
      changeTemplate()
    }
    setShowModal(false)
  }

  const onCancelModal = () => {
    if (modalType === 'caseChange') {
      setCaseId(prevCaseId)
    } else {
      setTemplate(prevTemplate)
    }
    setShowModal(false)
  }

  const updateEditorState = newState => {
    setHtmlContent(newState)
    const edited = newState !== '<p><br></p>'
    setBodyHasBeenEdited(edited)
  }

  const updatePlainText = newText => {
    setPlainText(newText)
    const edited = newText !== ''
    setBodyHasBeenEdited(edited)
  }

  const updateContact = value => {
    setContact(value)
    if (value.affiliation !== 'self' && value.affiliation !== undefined) {
      setBorrowerName(`On behalf of ${borrowerName}`)
    } else {
      setBorrowerName(borrowerName)
    }
  }

  const updateAttachments = value => {
    setAttachments(value)
  }

  const formatTime = date => {
    return formatRelative(date, {
      isCapitalized: true,
      showTime: false
    })
  }

  const onCancel = () => {
    onClose()
    onDeleteDraft(id)
  }

  useEffect(() => {
    if (associatedCase && !draftFromStorage.caseId) {
      onChangeCaseId(associatedCase.value)
    }
    if (previousInteraction.theme && !draftFromStorage.theme) {
      const _theme = _.find(
        outboundInteractionReasonOptions,
        reason => reason.value === previousInteraction.theme
      )
      updateTheme(_theme)
    }
  }, [
    associatedCase,
    draftFromStorage,
    onChangeCaseId,
    previousInteraction,
    updateSubjectLine,
    updateTheme
  ])

  const canSendInteraction = useCanSendInteraction({
    channel,
    hasContact,
    hasMadeMinimumEdits,
    hasNoTemplateFoundError,
    hasTheme,
    isContentEmpty,
    isSending,
    isUnbound,
    subjectLine
  })

  const showSpinner = isSending || isLoading || isFetchingPreview

  const isUploadLinkDisabled = useMemo(() => {
    return (
      _.isEmpty(contact) ||
      channel !== 'email' ||
      subject?.previewOnly === false ||
      isPreviewMode
    )
  }, [contact, channel, subject, isPreviewMode])

  const isAttachmentsDisabled = !caseId || isPreviewMode

  const renderNull = isLoading && !isSending && !isFetchingPreview

  /**
   * This useMemo value is mostly intended to format the
   * returned html for mail interactions so that the
   * page content has the correct padding when viewing it
   * in the resource overlay. The final version we send to
   * LOB may or may not need this specific padding but for
   * visual purposes, we'll be adding padding to the page
   * element when previwing mail interactions.
   */
  const editedDraftPreview = useMemo(() => {
    return editDraftPreview(draftPreview)
  }, [draftPreview])

  return (
    <>
      <MainContainer>
        {isSupercase ? (
          <ToPopulations
            activePopulations={activePopulations}
            activeBorrowers={activeBorrowers}
            maximized={maximized}
            onChange={setPopulationId}
            value={populationId}
            readOnly={isPreviewMode}
          />
        ) : (
          <To
            borrowerId={borrowerId}
            channel={channel}
            contact={contact}
            contactInfo={contactInfo}
            hideLock
            maximized={maximized}
            onChangeContact={updateContact}
            readOnly={isReplyToUnboundInteraction || isPreviewMode}
            setContact={setContact}
          />
        )}
        <ScrollableArea>
          {!isUnbound && (
            <Header
              borrowerId={borrowerId}
              caseId={caseId}
              contact={contact}
              hideLock
              maximized={maximized}
              onChangeCaseId={onChangeCaseId}
              onChangeTemplate={onChangeTemplate}
              readOnly={isPreviewMode}
              template={template}
            />
          )}
          {isSupercase && <SupercaseId supercaseId={supercaseId} />}
          <Reason
            theme={theme}
            onChange={updateTheme}
            maximized={maximized}
            isControlled
            readOnly={isPreviewMode}
          />
          {channel === 'email' && (
            <Subject
              maximized={maximized}
              onChange={updateSubjectLine}
              readOnly={template !== 'freeFormBranded' || isPreviewMode}
              subject={subjectLine}
            />
          )}

          {renderNull ? null : hasNoTemplateFoundError && channel !== 'text' ? (
            <NoTemplateFound>{htmlContent}</NoTemplateFound>
          ) : mode === DRAFT_MODE || isFetchingPreview ? (
            <Editor
              attachments={attachments}
              borrowerId={borrowerId}
              channel={channel}
              contact={contact}
              htmlContent={htmlContent}
              id={`DraftInteraction-${id}`}
              insertUploadLink={insertUploadLink}
              maximized={maximized}
              onChangeEditorState={updateEditorState}
              onChangePlainText={updatePlainText}
              plainText={plainText}
              previewOnly={subject?.previewOnly !== false}
              previousInteractions={previousInteractions}
              quillRef={quillRef}
              templateStyles={templateStyles}
            />
          ) : mode === PREVIEW_MODE && !isFetchingPreview ? (
            <Iframe content={editedDraftPreview} />
          ) : null}
        </ScrollableArea>
        {!isLoading && (
          <SendRow
            attachments={attachments}
            borrowerId={borrowerId}
            caseId={caseId}
            channel={channel}
            documents={documents}
            formatTime={formatTime}
            id={`DraftInteraction-${id}`}
            insertUploadLink={() =>
              insertUploadLink({
                quillRef,
                borrowerId,
                contactId: contact.id,
                caseId
              })
            }
            isAttachmentsDisabled={isAttachmentsDisabled}
            isSubmitDisabled={!canSendInteraction}
            isUploadLinkDisabled={isUploadLinkDisabled}
            maximized={maximized}
            mode={mode}
            onCancel={onCancel}
            onPreview={onPreview}
            onSubmit={onSend}
            previewOnly={subject?.previewOnly !== false}
            setMode={setMode}
            updateAttachments={updateAttachments}
          />
        )}
        {showSpinner && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </MainContainer>

      {showModal && (
        <Modal
          canClose={showSpinner}
          onClose={() => setShowModal(false)}
          width={476}
        >
          <Dialog
            confirmLabel={modalConfirmText}
            onConfirm={onConfirmModal}
            cancelLabel='Cancel'
            onCancel={onCancelModal}
          >
            {modalMessage}
          </Dialog>
        </Modal>
      )}
      {showSendErrorModal && (
        <Modal
          canClose={!showSpinner}
          onClose={() => setShowSendErrorModal(false)}
          width={476}
        >
          <Dialog
            confirmLabel='Ok'
            onConfirm={() => setShowSendErrorModal(false)}
            title='Interaction failed'
          >
            {sendError?.message ||
              'There was an error while sending this interaction.'}
          </Dialog>
        </Modal>
      )}
    </>
  )
}

DraftInteraction.propTypes = {
  borrower: PropTypes.object,
  borrowerName: PropTypes.string,
  id: PropTypes.string,
  maximized: PropTypes.bool,
  onClose: PropTypes.func,
  previousInteractions: PropTypes.bool,
  setBorrowerName: PropTypes.func,
  supercaseId: PropTypes.string
}

DraftInteraction.defaultProps = {
  onClose: () => {}
}

DraftInteraction.dependencies = {
  getTemplatePreview,
  localStorage,
  sendCommunication,
  useContext,
  useDispatch,
  useEntity,
  useFirstLoanIdOfCase,
  useSelector,
  useState,
  useTime,
  bulkSend,
  useWrite
}

export { DRAFT_MODE, PREVIEW_MODE }
export default DraftInteraction

import makeDifference from './makeDifference'
import makeGetNow from './makeGetNow'

const makeMinutesSince = () => {
  const minutesSince = timeordate => {
    const difference = makeDifference()
    const getNow = makeGetNow()

    if (!timeordate) {
      return ''
    }

    const minutes = difference(getNow(), timeordate, 'minutes')
    return `${minutes} mins`
  }

  return minutesSince
}

export default makeMinutesSince

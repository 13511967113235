/**
 * TODO: IMPORT THE <TOPBAR /> COMPONENT TO HERE AND REPLACE
 * IT WITH THE <TITLEBAR /> STYLED COMPONENT IN THE <RESOURCE />
 * COMPONENT.
 */

// Third party --------------
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'

// Components ---------------
import BottomActiveSection from './BottomActiveSection'
import BottomCallCompletedSection from './BottomCallCompletedSection'
import BottomPostDialSection from './BottomPostDialSection'
import BottomPredialSection from './BottomPredialSection'
import Case from '../Case'
import Outcome from '../Outcome'
import Reason from '../Reason'
import SelectOutcome from './SelectOutcome'
import TopActiveSection from './TopActiveSection'
import TopPostDialSection from './TopPostDialSection'
import TopPredialSection from './TopPredialSection'
import InteractionModalContent, {
  DIALOG_LOCK,
  DIALOG_UNAUTHORIZED
} from '../InteractionModalContent'
import { Modal, Spinner } from 'core/components'

// Rest ---------------------
import addAssociation from 'core/actions/cases/addAssociation'
import createNote from 'core/actions/notes/createNote'
import deCamelize from 'core/helpers/deCamelize'
import isCollectionLoading from 'core/helpers/isCollectionLoading'
import updateInteraction from 'core/actions/interactions/updateInteraction'
import updateUnboundInteraction from 'core/actions/interactions/updateUnboundInteraction'
import updateVoiceCall from 'core/actions/interactions/updateVoiceCall'
import useFirstLoanIdOfCase from 'core/components/ResourceOverlay/hooks/useFirstLoanIdOfCase'
import variables from 'core/styles/variables'
import {
  ACTIVE,
  CALL_COMPLETED,
  PREDIAL,
  POST_DIAL
} from 'contexts/CallModeContext'

import { formatPhone } from 'core/helpers/phoneNumberFormatter'
import { formattedContactOption, getDefaultOptions } from './helpers'
import { getPrimarySelf } from 'core/hooks/useContactsSelectOptions'
import {
  useCases,
  useCompanyId,
  useContact,
  useContacts,
  useHasPermission,
  useIsLoading,
  useOnMount
} from 'core/hooks'
import {
  useCallModeContext,
  useTelephonyContacts,
  useTelephonyContext,
  useTwilioDeviceContext,
  useVoiceTaskContext,
  useTelephonyTitle
} from './hooks'
import {
  INTERACTION,
  RELATED,
  SET_CAN_INTERACT_STATUS,
  CREATE_INTERACTION
} from 'core/actions/constants'
import {
  connectWhenReady,
  fetchOutboundVoiceParams
} from 'crm/helpers/twilioClient'

// Styles -----------------------------
const TopSection = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid ${variables.colorBlack20};
`
const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 16px 16px;
  min-height: 80px;
`
const Container = styled.div`
  position: relative;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${variables.overlay};
`

const Telephony = props => {
  const { useDispatch } = Telephony.dependencies

  const { borrowerId: passedBorrowerId, id, onClose, options, setTitle } = props
  const {
    isInboundCall: isInboundCallFromOptions,
    _case: selectedCaseFromOptions,
    theme: selectedThemeFromOptions,
    isRedial,
    isRedialFromInboundCall
  } = options || {}
  const { device } = useTwilioDeviceContext()

  const dispatch = useDispatch()
  const companyId = useCompanyId()

  const { callMode, setCallModeCallCompleted } = useCallModeContext()

  // Telephony context ------
  const {
    callDuration,
    contactId,
    setContactId,
    hasBeenRecorded,
    setHasBeenRecorded,
    micOn,
    setMicOn,
    personId,
    setPersonId,
    recording,
    setRecording,
    onEndCall,
    setupActiveCallState,
    removeActiveCallDraft,
    draftVoiceInteraction,
    setDraftVoiceInteraction,
    onRedial,
    onNewCall
  } = useTelephonyContext()

  const {
    interaction: interactionFromDraftVoiceInteraction,
    callerPhoneNumber: callerPhoneNumberFromDraftVoiceInteraction,
    isInboundCall: isInboundCallFromDraftVoiceInteraction,
    selectedCase: selectedCaseFromDraftVoiceInteraction,
    selectedTheme: selectedThemeFromDraftVoiceInteraction,
    selectedOutcome: selectedOutcomeFromDraftVoiceInteraction,
    caseNote: caseNoteFromDraftVoiceInteraction
  } = draftVoiceInteraction || {}

  const isInboundCall =
    isInboundCallFromDraftVoiceInteraction || isInboundCallFromOptions || false

  // Voice task destructuring
  const { voiceTask: voiceTaskFromContext, setHasSavedCallOutcome } =
    useVoiceTaskContext()
  /**
   * Setting voice task to local state here so the task data
   * will remain here until the this component unmounts.
   * This helps with referencing the voice task data even the
   * task is marked as done but Telephony is still open.
   */
  const [voiceTask] = useState(voiceTaskFromContext)
  const { task } = voiceTask || {}
  const { attributes } = task || {}
  const { from: callerPhoneNumberFromTask, interaction: interactionFromTask } =
    attributes || {}

  const callerPhoneNumber =
    callerPhoneNumberFromDraftVoiceInteraction ||
    callerPhoneNumberFromTask ||
    null

  const initialCaseNote = caseNoteFromDraftVoiceInteraction || ''
  const initialInteraction = !!interactionFromDraftVoiceInteraction
    ? interactionFromDraftVoiceInteraction
    : !!interactionFromTask && isInboundCall
    ? interactionFromTask
    : null
  const initialSelectedCase =
    selectedCaseFromOptions || selectedCaseFromDraftVoiceInteraction || null
  const initialSelectedOutcome =
    selectedOutcomeFromDraftVoiceInteraction || null
  const initialSelectedTheme =
    selectedThemeFromOptions || selectedThemeFromDraftVoiceInteraction || null

  // Local state ----------------------
  const [caseNote, setCaseNote] = useState(initialCaseNote)
  const [interaction, setInteraction] = useState(initialInteraction)
  const [isAttemptingToConnectCall, setIsAttemptingToConnectCall] =
    useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedCase, setSelectedCase] = useState(initialSelectedCase)
  const [selectedOutcome, setSelectedOutcome] = useState(initialSelectedOutcome)
  const [selectedTheme, setSelectedTheme] = useState(initialSelectedTheme)
  const [showLockInteractionModal, setShowLockInteractionModal] =
    useState(false)
  const [dialogToShow, setDialogToShow] = useState(null)
  const [userInputtedPhoneNumber, setUserInputtedPhoneNumber] = useState(null)
  const [hasRedialed, setHasRedialed] = useState(false)

  // Tracks when the default options get initially set
  const hasSetDefaultOptions = useRef(false)

  // Tracks when the primary contact is initially set
  // If a draft voice interaction exists then we'll need
  // to disallow any initial primary contact setup
  const primaryContactSet = useRef(
    !!interactionFromDraftVoiceInteraction || false
  )

  // Manages the most relevant personId
  const { personId: personIdFromInteraction } = interaction || {}

  const isInteractionLoading = useIsLoading(
    `Interactions.byId(id=${interaction?.id})`
  )

  const isUserInteractionDisabled =
    isInteractionLoading || isAttemptingToConnectCall

  // Set the most relevant personId
  useEffect(() => {
    const validPassedBorrowerId = !isInboundCall ? passedBorrowerId : null
    const mostRelevantPersonId =
      personIdFromInteraction || validPassedBorrowerId || null
    setPersonId(mostRelevantPersonId)
  }, [
    draftVoiceInteraction,
    isInboundCall,
    passedBorrowerId,
    personIdFromInteraction,
    setPersonId
  ])

  // Calculates all contact related data
  const contacts = useContacts({ personId })

  const contactByValue = useMemo(() => {
    const contactsById = contacts?.byId || {}
    const collection = _.values(contactsById)
    return _.find(collection, contact => {
      return contact?.value === callerPhoneNumber
    })
  }, [callerPhoneNumber, contacts])

  const { contactId: contactIdFromInteraction } = interaction || {}
  const { id: idFromContactByValue } = contactByValue || {}
  const mostRelevantContactId = !personId
    ? null
    : isInboundCall
    ? idFromContactByValue
    : contactIdFromInteraction || contactId

  const contact = useContact(contactId || mostRelevantContactId, { personId })

  const groupedContactList = useTelephonyContacts({ borrowerId: personId })

  // Gets formatted widget title
  const $title = useTelephonyTitle({
    personId,
    callMode,
    callDuration,
    contact
  })

  // Cases options
  const cases = useCases({ personId })
  const casesOptions = useMemo(() => {
    const closedCaseStatuses = ['completed', 'canceled']
    const collection = _.values(cases.byId)
    const filteredCases = _.filter(
      collection,
      c => !_.includes(closedCaseStatuses, c.status)
    )
    return _.map(filteredCases, _case => {
      const { name, caseType } = _case
      const labelName = name ?? deCamelize(caseType)
      const label = `${_case.id} ${labelName}`
      return { label, value: _case.id }
    })
  }, [cases])

  // Permissions
  const canReadSensitive = useHasPermission('interaction:read.sensitive')

  // loanId of case
  const loanId = useFirstLoanIdOfCase({
    borrowerId: personId,
    caseId: selectedCase?.value
  })

  // Default options for Contact, Case and Theme/Reason
  const shouldSetDefaultOptions =
    !isInboundCall &&
    !hasSetDefaultOptions.current &&
    !isCollectionLoading(cases) &&
    !isCollectionLoading(contacts) &&
    options !== undefined

  const {
    defaultCallerPhoneNumber,
    defaultCase,
    defaultContact,
    defaultTheme
  } = useMemo(() => {
    return getDefaultOptions({
      casesOptions,
      groupedContactList,
      options
    })
  }, [casesOptions, groupedContactList, options])

  // Set primary self contact if available and contact is null
  const isGrouped = true
  const primaryContact = getPrimarySelf(groupedContactList, isGrouped)
  const shouldSetPrimaryContact =
    !isInboundCall &&
    groupedContactList !== null &&
    contact.loadingStatus === undefined &&
    primaryContact &&
    !primaryContactSet.current &&
    !defaultContact &&
    !defaultCallerPhoneNumber &&
    !isRedial

  const unidentfiedCallerOption = callerPhoneNumber
    ? { label: formatPhone(callerPhoneNumber), value: callerPhoneNumber }
    : null

  const validSelectedContactOption = contact?.id
    ? contact
    : userInputtedPhoneNumber || unidentfiedCallerOption

  const isDialButtonDisabled =
    (!userInputtedPhoneNumber?.value && !contact?.id) || !selectedTheme

  const isSaveButtonDisabled = !selectedOutcome || !selectedTheme || isSaving

  const isMakeAnotherCallDisabled = !personId

  /**
   * If options is passed in and the default options have
   * not be set, we want to show the spinner. This helps
   * prevent users from manipulating the widget UI while
   * it is still loading data to set any default options.
   */
  const isLoading =
    !isInboundCall &&
    (isCollectionLoading(contacts) ||
      (hasSetDefaultOptions.current === false && options !== undefined))

  useOnMount(() => {
    // Setup active call state on mount for inbound calls only
    if (isInboundCall && !draftVoiceInteraction) {
      setupActiveCallState()
    }
  })

  useEffect(() => {
    setContactId(mostRelevantContactId)
  }, [mostRelevantContactId, setContactId])

  /**
   * Handles setting the correct resource title. personId,
   * callMode, callDuration and selected contact all have
   * an affect on the final title.
   */
  useEffect(() => {
    setTitle($title)
  }, [setTitle, $title])

  /**
   * Only save current voice interaction to localStorage
   * if call mode is ACTIVE or POST_DIAL
   */
  useEffect(() => {
    const draftObject = {
      callDuration,
      callerPhoneNumber,
      caseNote,
      hasBeenRecorded,
      interaction,
      isInboundCall,
      personId,
      selectedCase,
      selectedOutcome,
      selectedTheme
    }
    if (callMode === ACTIVE || callMode === POST_DIAL)
      setDraftVoiceInteraction(draftObject)
  }, [
    callDuration,
    callMode,
    callerPhoneNumber,
    caseNote,
    hasBeenRecorded,
    interaction,
    isInboundCall,
    personId,
    selectedCase,
    selectedOutcome,
    selectedTheme,
    setDraftVoiceInteraction
  ])

  /**
   * Reset the selected case and reason if no personId.
   * This can happen if the caller is unidentified or
   * when disassociating a person from a voice interaction.
   */
  useEffect(() => {
    if (personId === null) {
      setSelectedCase(null)
      setSelectedTheme(null)
    }
  }, [personId, setSelectedCase, setSelectedTheme])

  useEffect(() => {
    /**
     * Only run this if options are passed in and the default values
     * have not been set. Also makes sure that all contacts and cases
     * are fully loaded to accurately match the correct option
     */
    if (shouldSetDefaultOptions) {
      // Incoming preset options
      const { isUserInputtedPhoneNumber, id } = defaultContact || {}
      if (defaultContact) {
        if (isUserInputtedPhoneNumber || !id) {
          setContactId(null)
          setUserInputtedPhoneNumber(defaultContact)
        } else {
          setContactId(id)
        }
      }
      if (defaultCase) setSelectedCase(defaultCase)
      if (defaultTheme) setSelectedTheme(defaultTheme)
      hasSetDefaultOptions.current = true
    }
  }, [
    defaultCase,
    defaultContact,
    defaultTheme,
    cases,
    casesOptions,
    groupedContactList,
    options,
    shouldSetDefaultOptions,
    setContactId
  ])

  useEffect(() => {
    if (shouldSetPrimaryContact) {
      setContactId(primaryContact.id)
      primaryContactSet.current = true
    }
  }, [primaryContact, primaryContactSet, setContactId, shouldSetPrimaryContact])

  // ----------------------------------
  // Methods --------------------------

  // Predial
  const onClickClose = () => {
    onClose(id, 'telephony')
    setDraftVoiceInteraction(null)
  }

  const onClickDial = useCallback(async () => {
    setHasRedialed(true)
    setIsAttemptingToConnectCall(true)

    const phone =
      contact.value ||
      userInputtedPhoneNumber?.value ||
      validSelectedContactOption?.value ||
      null

    const params = {
      interactionTheme: selectedTheme.value,
      personId,
      loanId,
      contactId: userInputtedPhoneNumber?.value ? null : contact.id,
      phone
    }

    try {
      const resp = await fetchOutboundVoiceParams(params)
      const { interaction, twilioConnect } = resp.data
      // Keep track of the interaction that is created when
      // hitting the /make-outbound-voice-params endpoint
      setInteraction(interaction)
      setupActiveCallState()
      // This makes the actual call
      connectWhenReady({ device, twilioConnect })
    } catch (e) {
      if (e.status === 400) {
        dispatch({
          type: SET_CAN_INTERACT_STATUS,
          complianceGuardErrorMessage: e.message
        })
      } else {
        // TODO: Handle other error types, like selecting a
        // case that has an associate loan for certain themes.
      }
    }
    setIsAttemptingToConnectCall(false)
  }, [
    contact,
    device,
    dispatch,
    loanId,
    personId,
    selectedTheme,
    setupActiveCallState,
    userInputtedPhoneNumber,
    validSelectedContactOption?.value
  ])

  const hasRequiredFieldsToDial =
    selectedTheme?.value &&
    (contact?.id ||
      userInputtedPhoneNumber?.value ||
      validSelectedContactOption?.value)

  const canRedial =
    callMode === PREDIAL &&
    validSelectedContactOption &&
    selectedTheme &&
    hasRequiredFieldsToDial &&
    !isRedialFromInboundCall &&
    !hasRedialed

  useEffect(() => {
    if (isRedial && canRedial) {
      onClickDial()
    }
  }, [isRedial, canRedial, onClickDial])

  const _onUpdateInteraction = body => {
    try {
      if (personId) {
        dispatch(
          updateInteraction({
            body,
            key: 'UpdateInteraction',
            id: interaction.id,
            personId
          })
        )
      } else {
        dispatch(
          updateUnboundInteraction({
            body: {
              personId
            },
            companyId,
            id: interaction.id,
            key: `UpdateUnboundInteraction=${interaction.id}`
          })
        )
      }
      // Update the interaction in local state
      setInteraction({ ...interaction, ...body })
    } catch (e) {
      throw Error({ title: 'Could not update interaction', error: e })
    }
  }

  // Post dial
  const onSelectOutcome = option => {
    setSelectedOutcome(option)
    const body = { status: option.status, statusDetails: option.value }
    _onUpdateInteraction(body)
  }

  const onAddAssociation = () => {
    dispatch(
      addAssociation({
        key: `AddAssociation-${selectedCase.value}`,
        filters: { personId, caseId: selectedCase.value },
        body: {
          objectType: INTERACTION,
          objectId: interaction.id,
          relation: RELATED
        }
      })
    )
  }

  const onCreateNote = () => {
    dispatch(
      createNote({
        key: 'NewNote',
        personId,
        caseId: selectedCase.value,
        body: { content: caseNote }
      })
    )
  }

  const onClickSave = async () => {
    dispatch({ type: CREATE_INTERACTION, payload: interaction, personId })

    setIsSaving(true)

    const { status, value: statusDetails } = selectedOutcome
    const { value: theme } = selectedTheme

    const body = { status, statusDetails, theme }

    _onUpdateInteraction(body)

    if (selectedCase) onAddAssociation()

    if (selectedCase && caseNote?.length > 0) onCreateNote()

    removeActiveCallDraft()
    setHasSavedCallOutcome(true)
    setCallModeCallCompleted()
    setIsSaving(false)
    setDraftVoiceInteraction(null)
  }

  // Call completed
  const onClickRedial = () => {
    const redialOptions = {
      _case: selectedCase?.value,
      contactIdOrValue:
        validSelectedContactOption?.id || validSelectedContactOption?.value,
      groupedContactListFromOptions: groupedContactList,
      theme: selectedTheme.value,
      isRedial: true,
      isRedialFromInboundCall: isInboundCall
    }
    hasSetDefaultOptions.current = false
    onRedial({ options: redialOptions })
  }

  const onClickNewCall = () => {
    onNewCall()
  }

  const onUpdateRecordingStatus = recording => {
    dispatch(
      updateVoiceCall({
        interactionId: interaction.id,
        options: { recordingStatus: recording ? 'in-progress' : 'paused' }
      })
    )
    setRecording(recording)
    if (recording) {
      setHasBeenRecorded(true)
    }
  }

  const onLockInteraction = () => {
    _onUpdateInteraction({ sensitive: true })
    setShowLockInteractionModal(false)
  }

  const _onLockClick = () => {
    const dialogType = interaction.sensitive ? DIALOG_UNAUTHORIZED : DIALOG_LOCK
    if (interaction.sensitive && canReadSensitive) {
      _onUpdateInteraction({ sensitive: false })
    } else {
      setDialogToShow(dialogType)
      setShowLockInteractionModal(true)
    }
  }

  const getSelectedContact = option => {
    const { id } = option || {}
    if (id) {
      setContactId(id)
      setUserInputtedPhoneNumber(null)
    } else if (option === null) {
      // If option is null, that means the user clicked the X to
      // clear the selection.
      setContactId(null)
      setUserInputtedPhoneNumber(null)
    } else {
      // Otherwise, the user is typing a phone number.
      setContactId(null)
      setUserInputtedPhoneNumber({ ...option, isUserInputtedPhoneNumber: true })
    }
  }

  return (
    <>
      {isLoading ? (
        <Spinner data-testid='spinner-main' height='272px' />
      ) : (
        <>
          <Container>
            <TopSection>
              {callMode.state === PREDIAL.state ? (
                <TopPredialSection
                  groupedContactList={groupedContactList}
                  selectedContact={formattedContactOption(
                    validSelectedContactOption
                  )}
                  getSelectedOption={getSelectedContact}
                />
              ) : callMode.state === ACTIVE.state ? (
                <TopActiveSection
                  callerPhoneNumber={callerPhoneNumber}
                  contact={formattedContactOption(contact)}
                  interaction={interaction}
                  isInboundCall={isInboundCall}
                  recording={recording}
                  setInteraction={setInteraction}
                  onUpdateRecordingStatus={onUpdateRecordingStatus}
                />
              ) : (
                <TopPostDialSection
                  borrowerId={personId}
                  callerPhoneNumber={callerPhoneNumber}
                  callDuration={callDuration}
                  contact={contact}
                  isInboundCall={isInboundCall}
                  recorded={hasBeenRecorded}
                  onLockClick={_onLockClick}
                  sensitiveData={interaction?.sensitive}
                />
              )}
            </TopSection>
            {personId && (
              <Case
                isControlled
                menuPlacement='top'
                onChange={setSelectedCase}
                options={casesOptions}
                value={selectedCase}
              />
            )}
            <Reason
              isControlled
              isInboundInteraction={isInboundCall}
              theme={selectedTheme}
              onChange={setSelectedTheme}
              menuPlacement='top'
            />
            {callMode.state === POST_DIAL.state && (
              <SelectOutcome
                maxMenuHeight={304}
                selectedOutcome={selectedOutcome}
                setSelectedOutcome={setSelectedOutcome}
              />
            )}
            {callMode.state === CALL_COMPLETED.state && (
              <Outcome
                onChange={onSelectOutcome}
                outcome={selectedOutcome}
                readOnly
                reasonString={selectedOutcome?.label}
                maxMenuHeight={304}
                menuPlacement='top'
              />
            )}
            <BottomSection>
              {callMode.state === PREDIAL.state ? (
                <BottomPredialSection
                  disabled={isDialButtonDisabled}
                  onClickClose={onClickClose}
                  onClickDial={onClickDial}
                />
              ) : callMode.state === ACTIVE.state ? (
                <BottomActiveSection
                  onClickEndCall={onEndCall}
                  micOn={micOn}
                  setMicOn={() => setMicOn(!micOn)}
                />
              ) : callMode.state === POST_DIAL.state ? (
                <BottomPostDialSection
                  caseNote={caseNote}
                  isSaveDisabled={isSaveButtonDisabled}
                  onClickSave={onClickSave}
                  selectedCase={selectedCase}
                  setCaseNote={setCaseNote}
                />
              ) : (
                <BottomCallCompletedSection
                  caseNote={caseNote}
                  isMakeAnotherCallDisabled={isMakeAnotherCallDisabled}
                  onClickRedial={onClickRedial}
                  onClickClose={onClickClose}
                  onClickNewCall={onClickNewCall}
                  selectedCase={selectedCase}
                />
              )}
            </BottomSection>
            {isUserInteractionDisabled && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </Container>
          {showLockInteractionModal && (
            <Modal
              onClose={() => setShowLockInteractionModal(false)}
              width={476}
            >
              <InteractionModalContent
                dialogToShow={dialogToShow}
                onCancel={() => setShowLockInteractionModal(false)}
                onLockInteraction={onLockInteraction}
              />
            </Modal>
          )}
        </>
      )}
    </>
  )
}

Telephony.propTypes = {
  borrowerId: PropTypes.string,
  callMode: PropTypes.shape({
    state: PropTypes.string,
    iconName: PropTypes.string
  }),
  id: PropTypes.string,
  options: PropTypes.shape({
    _case: PropTypes.string,
    contact: PropTypes.string,
    theme: PropTypes.string
  }),
  onClose: PropTypes.func,
  setTitle: PropTypes.func
}

Telephony.dependencies = {
  useDispatch
}

export default Telephony

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import MastercardIcon from './internal/custom/MastercardIcon'
import NothingIcon from './internal/custom/NothingIcon'
import AmexIcon from './internal/custom/AmexIcon'
import DiscoverIcon from './internal/custom/DiscoverIcon'
import VisaIcon from './internal/custom/VisaIcon'
import MaterialIconRounded from './internal/MaterialIconRounded'

/**
 * Rules for adding new icons to this library:
 *
 * Do not add any new icons to this registry without first obtaining
 * approval from the design team. This way they can control what icons
 * we want to consider as our standard, approved set.
 *
 * Keep the list alphabetized, ignoring case and non-alphanumeric
 * characters. For example:
 * - bar
 * - Foo // because 'bar' comes before 'foo'
 * - f-to // because 'foo' comes before 'fto'
 *
 * If adding an icon available in the Material UI icons library, our
 * go-to theme is "rounded". For rounded, set the value for the icon
 * in `names` to the component `MaterialIconRounded`, and make sure
 * the name is exactly the name of the icon as shown in the library:
 * https://material.io/resources/icons/?style=round
 * (Note that it uses snake_case for its naming, e.g. `3d_rotation`.)
 *
 * If making a custom icon, wrap with SvgIcon and store in ./internal,
 * then import here and add to `names`.
 *
 * If at any point we need to use a different theme for certain icons
 * from Material UI (e.g. filled or outlined), we just need another
 * component like `MaterialIconRounded`. There are instructions in that
 * component how to pattern match and use a different theme.
 *
 * If at any point we need to use a new library that is not Material UI,
 * just make another component kind of like `MaterialIconRounded` to use
 * for that library, where the props interface can be any subset of the
 * props of `Icon`, i.e. 'name', 'fontSize', 'color', and 'margin'.
 * However, it will most likely not need color or margin, and maybe not
 * fontSize, since those get set on the CSS of `Container` here, which
 * wraps it.
 */
export const names = {
  account_balance: MaterialIconRounded,
  account_circle: MaterialIconRounded,
  ac_unit: MaterialIconRounded,
  add: MaterialIconRounded,
  add_circle_outline: MaterialIconRounded, // identical to mui's control_point icon
  alarm: MaterialIconRounded,
  alarm_add: MaterialIconRounded,
  amex: AmexIcon,
  announcement: MaterialIconRounded,
  arrow_back: MaterialIconRounded,
  arrow_back_ios: MaterialIconRounded, // chevron that is bigger by filling the space more than keyboard_arrow_left
  arrow_forward_ios: MaterialIconRounded, // chevron that is bigger by filling the space more than keyboard_arrow_right
  arrow_drop_down: MaterialIconRounded,
  arrow_drop_up: MaterialIconRounded,
  arrow_upward: MaterialIconRounded,
  assignment: MaterialIconRounded,
  attach_file: MaterialIconRounded,
  block: MaterialIconRounded,
  business: MaterialIconRounded,
  cached: MaterialIconRounded,
  calendar_today: MaterialIconRounded,
  call_end: MaterialIconRounded,
  call_made: MaterialIconRounded,
  camera_alt: MaterialIconRounded,
  check: MaterialIconRounded,
  check_box: MaterialIconRounded,
  check_box_outline_blank: MaterialIconRounded,
  check_circle: MaterialIconRounded,
  clear: MaterialIconRounded, // identical to mui's close icon
  close_fullscreen: MaterialIconRounded,
  create: MaterialIconRounded,
  credit_card: MaterialIconRounded,
  date_range: MaterialIconRounded,
  description: MaterialIconRounded,
  discover: DiscoverIcon,
  dns: MaterialIconRounded,
  email: MaterialIconRounded, // identical to mui's mail icon
  event: MaterialIconRounded, // identical to mui's insert_invitation icon
  exit_to_app: MaterialIconRounded,
  expand: MaterialIconRounded,
  face: MaterialIconRounded,
  fast_forward: MaterialIconRounded,
  fiber_manual_record: MaterialIconRounded,
  flag: MaterialIconRounded, // mui does not have flag_outline, instead use outlined_flag
  gavel: MaterialIconRounded,
  help: MaterialIconRounded,
  help_outline: MaterialIconRounded,
  highlight_off: MaterialIconRounded,
  hourglass_empty: MaterialIconRounded,
  how_to_vote: MaterialIconRounded,
  history: MaterialIconRounded,
  home: MaterialIconRounded,
  info: MaterialIconRounded,
  keyboard_arrow_down: MaterialIconRounded, // identical to mui's expand_more icon
  keyboard_arrow_left: MaterialIconRounded, // identical to mui's chevron_left icon
  keyboard_arrow_right: MaterialIconRounded, // identical to mui's chevron_right icon
  keyboard_arrow_up: MaterialIconRounded, // identical to mui's expand_less icon
  launch: MaterialIconRounded,
  link: MaterialIconRounded,
  lock: MaterialIconRounded,
  lock_open: MaterialIconRounded,
  markunread_mailbox: MaterialIconRounded,
  mastercard: MastercardIcon,
  maximize: MaterialIconRounded,
  mic: MaterialIconRounded,
  mic_off: MaterialIconRounded,
  minimize: MaterialIconRounded,
  mobile_screen_share: MaterialIconRounded,
  monetization_on: MaterialIconRounded,
  north_east: MaterialIconRounded,
  nothing: NothingIcon,
  notification_important: MaterialIconRounded,
  open_in_full: MaterialIconRounded,
  open_in_new: MaterialIconRounded,
  outlined_flag: MaterialIconRounded,
  pause: MaterialIconRounded,
  people: MaterialIconRounded,
  person: MaterialIconRounded,
  phone: MaterialIconRounded, // identical to mui's call icon
  phone_callback: MaterialIconRounded,
  phone_in_talk: MaterialIconRounded,
  phone_paused: MaterialIconRounded,
  play_arrow: MaterialIconRounded,
  portrait: MaterialIconRounded,
  redo: MaterialIconRounded,
  reduce: MaterialIconRounded,
  remove: MaterialIconRounded,
  remove_circle_outline: MaterialIconRounded,
  replay: MaterialIconRounded,
  reply: MaterialIconRounded,
  reply_all: MaterialIconRounded,
  report: MaterialIconRounded,
  report_gmailerrorred: MaterialIconRounded,
  schedule: MaterialIconRounded, // identical to mui's query_builder, also what you'd probably call "clock"
  request_page: MaterialIconRounded,
  screen_share: MaterialIconRounded,
  search: MaterialIconRounded,
  settings: MaterialIconRounded,
  smartphone: MaterialIconRounded,
  snooze: MaterialIconRounded,
  star: MaterialIconRounded, // identical to mui's star_rate icon
  supervisor_account: MaterialIconRounded,
  sync: MaterialIconRounded,
  text_format: MaterialIconRounded,
  trending_down: MaterialIconRounded,
  undo: MaterialIconRounded,
  upload: MaterialIconRounded,
  vertical_align_bottom: MaterialIconRounded,
  visa: VisaIcon,
  voicemail: MaterialIconRounded,
  warning: MaterialIconRounded,
  waves: MaterialIconRounded,
  work: MaterialIconRounded
}

export const Container = styled.div`
  // This just makes sure we don't have some weird extra
  // space below the icon inside Container.
  display: ${p => (p.inline ? 'inline-flex' : 'flex')};
  font-size: ${p => p.fontSize};
  color: ${p => p.color};
  margin: ${p => p.margin};
  transform: ${p => (p.inline ? 'translateY(2.5px)' : 'none')};
  width: ${p => (p.fontSize !== 'inherit' ? p.fontSize : 'auto')};
  height: ${p => (p.fontSize !== 'inherit' ? p.fontSize : 'auto')};
`

/** Usage of Icon:
 * We were deliberate in our choice of not making Icon styleable. In other
 * words, you cannot do this with styled-components:
 * const StyledIcon = styled(Icon)`
 *   // css
 * `
 * Instead, just use the props fontSize, color, and margin if you want to
 * set those on the icon directly, or for setting the css of those values
 * or any other kind of css, wrap the Icon in another element and style
 * that instead.
 *
 * There is no onClick prop. If you want a button or a link for your icon,
 * you should instead actually make a button or link element and put the
 * icon inside as its content.
 *
 * Do not use any icons directly in our app. Always go through this
 * component.
 *
 * Material Design guidelines state that icons should usually have a
 * fontSize of '24px' and if a different size is needed, it should be
 * limited to one of these four sizes:
 * - '18px'
 * - '24px'
 * - '36px'
 * - '48px'
 * We decided to _not_ give a default of '24px' to Icon. Instead the default
 * is 'inherit', and you must always set the font size either with this
 * prop or with the css of some parent element. This gives more flexibility
 * and allows for sometimes having an icon inline with text at times we
 * want the icon to have the same font size as the text surrounding it. It
 * also makes it easier to set a group of icons to a font size with a single
 * setting of font size on the container of all the icons.
 */
const Icon = ({ name, fontSize, color, inline, margin }) => {
  const _name = name in names ? name : 'warning'
  const Component = names[_name]

  return (
    <Container
      fontSize={fontSize}
      color={color}
      margin={margin}
      inline={inline}
    >
      <Component
        name={_name}
        fontSize={fontSize}
        color={color}
        margin={margin}
      />
    </Container>
  )
}

Icon.propTypes = {
  /** The name of the icon. See `names` above. */
  name: PropTypes.oneOf(Object.keys(names)).isRequired,
  /** Sets the font-size css property, which translates directly to the
   * size of the icon. For example, '24px' means the icon svg will be
   * 24x24 pixels. Defaults to 'inherit' so that font-size can be set in
   * the css of a parent element if desired.
   */
  fontSize: PropTypes.string,
  /** Sets the color css property. Defaults to 'inherit' so that color
   * can be set in the css of a parent element if desired.
   */
  color: PropTypes.string,
  /** Sets the margin css property. Defaults to '0'. */
  margin: PropTypes.string,
  inline: PropTypes.bool
}

Icon.defaultProps = {
  fontSize: 'inherit',
  color: 'inherit',
  margin: '0'
}

Icon.names = names

export default Icon

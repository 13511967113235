import _ from 'lodash'

const isNested = (baseVal, newVal) => {
  return (
    !_.isArray(baseVal) &&
    !_.isArray(newVal) &&
    (_.isPlainObject(baseVal) || _.isPlainObject(newVal))
  )
}

const exists = val => !_.isNull(val) && !_.isUndefined(val)

const jsonDiff = (
  baseObject = {},
  newObject = {},
  maxDepth = 100,
  currentDepth = 1
) => {
  if (exists(maxDepth) && currentDepth > maxDepth) {
    return !_.isEqual(baseObject, newObject) ? newObject : {}
  }

  const diff = {}

  _.each(baseObject, (baseVal, key) => {
    const newVal = newObject[key]

    if (isNested(baseVal, newVal)) {
      const subDiff = jsonDiff(baseVal, newVal, maxDepth, currentDepth + 1)
      if (!_.isEmpty(subDiff)) diff[key] = subDiff
    } else {
      if (baseVal !== newVal) diff[key] = newVal
    }
  })

  _.each(newObject, (newVal, key) => {
    if (exists(newVal) && !baseObject.hasOwnProperty(key)) {
      diff[key] = newVal
    }
  })

  return diff
}

export default jsonDiff

import styled from 'styled-components/macro'
import variables from 'core/styles/variables'

const OuterContainer = styled.div`
  /* This defines a block-level element for the
  ** component, meaning it will take up the
  ** same amount of width space as an inlin-block
  ** element but also start on a new line */
  display: table;
  margin: ${p => p.margin};
`
const InnerContainer = styled.div`
  align-items: center;
  ${p => (p.flexReverse ? `flex-direction: row-reverse;` : '')}
  position: relative;

  .spinner {
    left: 2px;
    top: 0;
  }
`
const Label = styled.label`
  position: relative;
  padding: ${p =>
    p.padding ? p.padding : p.flexReverse ? '0 32px 0 0' : '0 0 0 32px'};
  line-height: 20px;
  color: ${p => p.color || variables.colorBlack90};
  font-size: ${p => p.fontSize || '16px'};
  cursor: pointer;
  display: block;

  &:before,
  &:after {
    position: absolute;
    box-sizing: border-box;
    border-radius: 10%;
    content: '';
  }

  &:before {
    top: 0;
    ${p => (p.flexReverse ? 'right: 0;' : 'left: 0;')}
    width: 20px;
    height: 20px;
  }

  &:after {
    top: 9px;
    ${p => (p.flexReverse ? 'right: 13px;' : 'left: 5px;')}
    width: 2px;
    height: 2px;
  }
`
const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked + ${Label}:before {
    border: 2px solid ${p => p.theme.primary};
    background-color: ${p => p.theme.secondary};
  }

  &:not(:checked) + ${Label}:before {
    border: 2px solid ${variables.colorBlack70};
    background-color: ${variables.colorWhite};
  }

  &:checked + ${Label}:after {
    transform: rotate(45deg);
    transition: all 0.2s ease-in;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    background: white;
  }
  &:not(:checked) + ${Label}:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + ${Label}:after {
    opacity: 1;
  }

  &:disabled + ${Label}:before {
    border: 2px solid ${variables.colorBlack50};
    background-color: ${variables.colorBlack50};
  }
`

export { InnerContainer, Input, Label, OuterContainer }
